@charset "utf-8";
$cmnPath: "../img/common/";
/*
#overview
modBtn
*/

/*
#styleguide
modBtn01

```
<a href="#" class="modBtn01">ボタン</a>

```
*/
.modBtn01 {
    display: block;
    color: #fff;
    @include fz(14);
    letter-spacing: 1em;
    text-indent: 1em;
    line-height: 1.4;
    text-align: center;
    text-decoration: none;
    padding: 6px 10px 4px;
    &.-type01 {
        background-color: #8C7F6C;
    }
    &.-type02 {
        background-color: #07568B;
    }
}//.modBtn01

/*
#styleguide
modBtn02

```
<a href="#" class="modBtn02 -type01">資料請求</a>
<a href="#" class="modBtn02 -type02">個別相談</a>

```
*/
.modBtn02 {
    border: 1px solid #fff;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    @include fz(14);
    color: #fff;
    font-family: $fontA1Gothic;
    font-weight: bold;
    line-height: (16 / 14);
    text-decoration: none;
    padding: 8px 4px;
    &::before {
        content: "";
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }
    &__txt {
        &-space {
            display: inline-block;
            width: 2em;
        }
    }
    &.-type01 {
        background-color: #37425B;
        &::before {
            background-image: url(#{$cmnPath}icon_btn02_01.svg);
            width: 24px;
            height: 28px;
            margin-right: 5px;
        }
    }
    &.-type02 {
        background-color: #9F9A82;
        &::before {
            background-image: url(#{$cmnPath}icon_btn02_02.svg);
            width: 29px;
            height: 29px;
            margin-right: 4px;
        }
    }
    &.-type03 {
        background-color: #83522C;
        &::before {
            background-image: url(#{$cmnPath}icon_btn02_03.svg);
            width: 23px;
            height: 32px;
            margin-right: 6px;
        }
    }
}//.modBtn02

/*
#styleguide
xxx

```
```
*/