@charset "utf-8";

/*
faq
*/
$id:faq;
$cmnPath:'../img/common/';
$imgPath:'../img/#{$id}/';
body#page_#{$id} {

    $_root:&;
    $_color: #293B5F;

    .myWrapper{
        color: $_color;
        font-family: $baseFontFamilyBold;
        padding-top: 133px;
        padding-bottom: (180-35)+px;
        overflow: hidden;
        @include tabletP{
            padding-top: 44px;
            padding-bottom: 90px;
        }

    }

    .myLead {
        @include fz(18);
        letter-spacing: 0.02em;
        line-height: (34 / 18);
        text-align: center;
        margin-bottom: 99px;
        @include tabletP {
            @include fz(15);
            margin-bottom: 50px;
        }
    }//.myLead

    .myList {
        $_: '.myList';
        @include fz(18);
        letter-spacing: 0.02em;
        line-height: (28 / 18);
        margin-bottom: 144px;
        @include tabletP {
            @include fz(15);
            margin-bottom: 72px;
        }
        &__item {
            background-color: #E9EBEF;
            &:not(:last-child) {
                margin-bottom: 58px;
                @include tabletP {
                    margin-bottom: 29px;
                }
            }
        }
        &-head {
            position: relative;
            display: table;
            width: 100%;
            &__inner {
                background: none;
                border: none;
                outline: none;
                display: table-cell;
                position: relative;
                color: inherit;
                text-align: left;
                vertical-align: middle;
                width: 100%;
                min-height: 80px;
                padding: 12px 25px+30px 12px 25px;
                @include tabletP {
                    padding: 6px 16px+21px 8px 18px;
                }
                &::after {
                    content: "";
                    background-image:
                        linear-gradient(to left, transparent 14px, #000 14px, #000 15px, transparent 15px),
                        linear-gradient(to top, transparent 14px, #000 14px, #000 15px, transparent 15px);
                    position: absolute;
                    top: 0;
                    bottom: 4px;
                    right: 27px;
                    display: block;
                    width: 30px;
                    height: 30px;
                    margin: auto;
                    @include tabletP {
                        background-image:
                            linear-gradient(to left, transparent 10px, #000 10px, #000 11px, transparent 11px),
                            linear-gradient(to top, transparent 10px, #000 10px, #000 11px, transparent 11px);
                        width: 21px;
                        height: 21px;
                        right: 12px;
                    }
                }
                &[aria-expanded = "true"] {
                    &::after {
                        background-image:
                            linear-gradient(to top, transparent 14px, #000 14px, #000 15px, transparent 15px);
                        @include tabletP {
                            background-image:
                                linear-gradient(to top, transparent 10px, #000 10px, #000 11px, transparent 11px);
                        }
                    }
                }
            }
        }
        &-detail {
            background-color: $_color;
            color: #fff;
            padding: 21px 25px 41px;
            @include tabletP {
                padding: 15px 18px 20px;
            }
            &[aria-hidden = "true"] {
                display: none;
            }
            &__title {
                margin-bottom: 22px;
                @include tabletP {
                    margin-bottom: 15px;
                }
            }
            &__txt {
                &Caution {
                    font-size: (15px/18px)*1em;
                }
            }
        }
    }//.myList

    .myBlockLink {
        $_: ".myBlockLink";
        text-align: center;
        &__txt {
            @include fz(18);
            letter-spacing: 0.02em;
            line-height: (28 / 18);
            text-align: center;
            margin-bottom: 40px;
            @include tabletP {
                @include fz(15);
                margin-bottom: 20px;
            }
        }
        &__link {
            background-color: #0071B9;
            display: block;
            color: #fff;
            text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.75);
            @include fz(21);
            letter-spacing: 0.02em;
            line-height: (28 / 21);
            text-align: center;
            text-decoration: none;
            max-width: 600px;
            padding: 31px 10px 32px;
            margin: 0 auto;
            @include tabletP {
                font-size: 18px;
                max-width: 450px;
                padding: 16px 8px 17px;
            }
        }
    }//.myBlockLink
}