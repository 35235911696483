@charset "utf-8";

/* link
------------------------------------------------------------*/
a {
	color: $baseLinkColor;
	text-decoration: underline;
	&:hover {
		text-decoration: none;
	}
}
a,button{
	&:hover {
		opacity:0.8;
		@include tabletL {
			opacity:1;
		}
	}
}

/* Fonts
------------------------------------------------------------*/
body{
	color: $baseColor;
	@include fz;
	font-family:$baseFontFamily;
	line-height:$baseLineHeight;
	overflow-wrap : break-word;
	-webkit-text-size-adjust: 100%;
	word-wrap : break-word;
	&.-scrollNone {
		overflow: hidden;
	}
	//IE11以下は別のフォントを指定する場合
	// &.IE {
	// 	font-family: "メイリオ",Verdana, "Hiragino Kaku Gothic ProN",  Meiryo, sans-serif;
	// }
}

input,button,textarea,select{
	color: $baseColor;
	font-family:$baseFontFamily;
	line-height:$baseLineHeight;
	font-size: 100%;
	//IE11以下は別のフォントを指定する場合
	// .IE &{
	// 	font-family: "メイリオ",Verdana, "Hiragino Kaku Gothic ProN",  Meiryo, sans-serif;
	// }
}

/*
#styleguide
container

```
<div class="container">
左右にpaddingつきます
</div>
```
*/
.container {
	margin-left: auto;
	margin-right: auto;
	max-width:$container-sm;
	padding-left:  ($containerPadding)+px;
	padding-right: ($containerPadding)+px;
	&.-size02 {
		max-width:$container-md;
	}
	&.-size03 {
		max-width:$container-lg;
	}
	&.-sizeMax {
		max-width:none;
	}
}
.modPcOnly {
	@include tabletP {
		display:none;
	}
}

.modSpOnly {
	@include tabletPmin {
		display:none;
	}
}

br{
	&.pc{
		@include tabletP{
			display: none;
		}
	}
	&.sp{
		@include tabletPmin{
			display: none;
		}
	}
}


[role="tabpanel"] {
	&[aria-hidden="true"] {
		display: none;
	}
	&[aria-hidden="false"] {
		display: block;
	}
}
