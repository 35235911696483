@charset "utf-8";

/*
lineup
*/
$id:lineup;
$cmnPath:'../img/common/';
$imgPath:'../img/#{$id}/';
body#page_#{$id} {

    $_root:&;

    .myWrapper{
        padding-top: 133px;
        padding-bottom: (103-35)+px;
        overflow: hidden;
        @include tabletP{
            padding-top: 44px;
            padding-bottom: 90px;
        }

    }
}
