@charset "utf-8";


/* form
------------------------------------------------------------*/
textarea,
input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="url"],
select{
	border:solid 1px #aaa;
	border-radius:5px;
	padding:10px;
	&.error {
		background-color:rgba(255,242,242,1.00);
		border:solid 2px $colorStrong;
	}
}


/* radio
-------------*/
input[type="radio"] {
	@include accessibilityHidden;
	& + .label {
		cursor: pointer;
		display: inline-block;
		padding: 3px 10px 3px 24px;
		position: relative;
		width:100%;
		&::before,
		&::after {
			border-radius: 100%;
			content: '';
			position: absolute;
			top: 50%;
			transition: all .2s;
		}
		&::before {
			background: #EEE;
			border: 1px solid #ccc;
			height: 16px;
			left: 0;
			margin-top: -9px;
			width: 16px;
		}
		&::after {
			background: $colorBlue;
			height: 10px;
			left: 3px;
			margin-top: -6px;
			opacity: 0;
			transform: scale(0.5);
			width: 10px;
		}
		&:hover {
			&::before {
				background: #FEFEFE;
			}
		}
	}
	&.error {
		& + .label {
			&::before{
				background-color:rgb(255, 242, 242);
				border: 1px solid $colorStrong;
			}
		}
	}
	&:checked {
		& + .label {
			&::before {
				background: #fff;
				border: 1px solid $colorBlue;
			}
			&::after {
				opacity: 1;
				transform: scale(1);
			}
		}
	}
}

/* checkbox
-------------*/
input[type="checkbox"] {
	@include accessibilityHidden;
	& + .label {
		cursor: pointer;
		display: inline-block;
		padding: 3px 10px 3px 22px;
		position: relative;
		transition: all .2s;
		&::before,
		&::after {
			content: '';
			position: absolute;
		}
		&::before {
			background: #f4f4f4;
			border: 1px solid #ccc;
			border-radius: 3px;
			height: 14px;
			left: 0;
			margin-top: -8px;
			top: 50%;
			width: 14px;
		}
		&::after {
			border-bottom: 2px solid $colorBlue;
			border-left: 2px solid $colorBlue;
			height: 4px;
			left: 3px;
			margin-top: -4px;
			opacity: 0;
			top: 50%;
			transform: rotate(-45deg) scale(0.5);
			width: 8px;
		}
		&:hover {
			&::before {
				background: #fff;
			}
		}
	}
	&:checked {
		& + .label {
			&::before {
				background: #fff;
				border: 1px solid $colorBlue;
			}
			&::after {
				opacity: 1;
				transform: rotate(-45deg) scale(1);
			}
		}
	}
}
