@charset "utf-8";

/*
#overview
modTitle
*/

/*
#styleguide
modTitlePage

class付与して各ページの位置調整。
```
    <header class="modTitlePage -asset">
        <div class="modTitlePage__inner">
            <h1 class="modTitlePage__title">Asset Management</h1>
        </div>
    </header>
```
*/

$_pagetitle:(
    asset:(
    	img:'../img/asset/bg_title'
    ),
    lineup:(
    	img:'../img/lineup/bg_title'
    ),
    reason:(
    	img:'../img/reason/bg_title'
    ),
    archives:(
    	img:'../img/archives/bg_title'
    ),
    voice:(
    	img:'../img/voice/bg_title'
    ),
    faq:(
    	img:'../img/faq/bg_title'
    ),
);


.modTitlePage{
    margin-top: -$headerHeight;
    height: get_vw(500,1920);
    @include tabletP {
        margin-top: -$headerHeightSp;
        height: get_vw(250,375);
    }

    $_:&;

    &__inner{
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        height: get_vw(500,1920);
        @include tabletP {
            height: get_vw(250,375);
        }
    }

    @each $name,$data in $_pagetitle {
        &.-#{$name} #{$_}__inner{

            @include tabletPmin{
                background-image: url(#{map-get( $data , img )}.jpg);

                @include retina(){
                    background-image: url(#{map-get( $data , img )}@2x.jpg);
                }
            }

            @include tabletP{
                background-image: url(#{map-get( $data , img )}_sp.jpg);

                @include retina(){
                    background-image: url(#{map-get( $data , img )}_sp@2x.jpg);
                }

            }
        }
    }



    &__title{
        position: absolute;
        color:#fff;
        font-weight: bold;
        font-size: get_vw(63,1920);
        font-family: $fontMontserrat;
        line-height: 1;
        @include tabletP{
            left: 0;
            right: 0;
            top: 20px;
            bottom: 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            text-align: center;
            font-size: get_vw(22,357);
        }

        @at-root #{$_}.-asset &{
            @include tabletPmin{
                right: get_vw(453,1920);
                bottom: get_vw(23,1920);
            }
        }

        @at-root #{$_}.-lineup &{
            @include tabletPmin{
                right: 50.39583vw;
                bottom: 10.42708vw
            }
        }

        @at-root #{$_}.-reason &{
            @include tabletPmin{
                right: get_vw(1198,1920);
                bottom: get_vw(277,1920);
            }
        }

        @at-root #{$_}.-archives &{
            @include tabletPmin{
                right: get_vw(276,1920);
                bottom: get_vw(174,1920);
            }
        }

        @at-root #{$_}.-voice &{
            @include tabletPmin{
                right: get_vw(390,1920);
                bottom: get_vw(208,1920);
            }
        }

        @at-root #{$_}.-faq &{
            @include tabletPmin{
                left: get_vw(517,1920);
                bottom: get_vw(208,1920);
            }
        }

    }



}//.modTitlePage



/*
#styleguide
modTitle01

```
    <header class="modTitle01" data-aos="fade-up" data-aos-anchor-placement="center-bottom">
        <h2 class="modTitle01__inner"><span class="modTitle01__txt">不動産投資によって”得られる可能性のある”</span><span class="modTitle01__txt">４つのメリット</span></h2>
    </header>
```
*/

.modTitle01{
    margin-bottom: 95px;
    text-align: center;
    @include tabletP{
        margin-bottom: 60px;
    }

    &__inner{
        position: relative;
        display: inline-block;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 1.4;
        font-family: $fontMincho;
        @include fz(29);

        @include tabletPmin{
            padding-top: 8px;
            padding-left: 37px;
            padding-bottom: 15px;

        }

        @include tabletP{
            line-height: 1;
        }

        @include sphoneP{
            @include fz(15);
        }

        @include sphoneSE{
            @include fz(13);
        }


        &::before,
        &::after
        {
            position: absolute;
            background-color: #000000;
        }

        &::before{
            content:"";
            left: 12px;
            top: 0;
            width: 1px;
            @include tabletPmin{
                bottom: 0;

            }
            @include tabletP{
                height: calc(1em + 15px);
                left: 5px;
            }
        }

        &::after{
            bottom: 10px;
            left: 0;
            right: 0;
            height: 1px;
            @include tabletPmin{
                content:"";

            }
        }
    }

    br{
        @include tabletPmin{
            display: none;
        }
    }

    &__txt{
        display: inline-block;
        @include tabletP{
            border-bottom: solid 1px #000;
            padding-bottom: 3px;
        }

        &:first-child{
            @include tabletP{
                display: block;
                padding-left: 20px;
                padding-bottom: 6px;
                margin-bottom: 10px;
                width: 100%;
            }
        }
    }
}//.modTitle01

/*
#styleguide
modTitlePageSupport

```
<h1 class="modTitlePageSupport -support_request_detail">
    <span class="modTitlePageSupport__num">
        <picture>
            <source srcset="/assets/img/request_detail/txt_title_sp.svg" media="(max-width: 768px)">
            <img src="/assets/img/request_detail/txt_title.svg" alt="support01">
        </picture>
    </span>
    <span class="modTitlePageSupport__txt">資料のお申込み</span>
</h1>
```
*/
.modTitlePageSupport {
    $_: &;
    $_page_list:(
        request_detail:(
            img:'../img/request_detail/bg_title'
        ),
        counseling_detail:(
            img:'../img/counseling_detail/bg_title'
        ),
        loan_detail:(
            img:'../img/loan_detail/bg_title'
        ),
    );
    &__num {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: block;
        text-align: center;
        width: 100%;
        height: 100%;
        @each $name, $data in $_page_list {
            #{$_}.-support_#{$name} & {
                @include tabletPmin{
                    background-image: url(#{map-get( $data , img )}.jpg);
    
                    @include retina(){
                        background-image: url(#{map-get( $data , img )}@2x.jpg);
                    }
                }
    
                @include tabletP{
                    background-image: url(#{map-get( $data , img )}_sp.jpg);
    
                    @include retina(){
                        background-image: url(#{map-get( $data , img )}_sp@2x.jpg);
                    }
    
                }
            }
        }
    }
    &__txt {
        display: block;
        color: #000;
        @include fz(30);
        font-family: $baseFontFamilyBold;
        font-weight: bold;
        letter-spacing: 0.04em;
        line-height: 1.4;
        text-align: center;
        padding: 76px 30px 73px;
        @include tabletP {
            @include fz(28);
            padding: 24px 20px 22px;
        }
    }
}//.modTitlePageSupport