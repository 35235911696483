@charset "utf-8";

/*
support
*/
$id:support;
$cmnPath:'../img/common/';
$imgPath:'../img/#{$id}/';
body#page_#{$id} {
    $_root: &;
    .gHeader {
        background-color: #7E7E7E;
    }

    .myWrapper {
        $_: ".myWrapper";
        padding-bottom: 100px;
        @include tabletP {
            padding-bottom: 30px;
        }
    }

    .myMv {
        $_: ".myMv";
        background: url(#{$imgPath}bg_mv.jpg) no-repeat right center;
        background-size: cover;
        @include tabletPmin {
            display: flex;
            align-items: center;
            height: 840px;
            margin-bottom: 60px;
        }
        @include retina {
            background-image: url(#{$imgPath}bg_mv@2x.jpg);
        }
        @include tabletP {
            background-image: url(#{$imgPath}bg_mv_sp.jpg);
            height: 450px;
            margin-bottom: 30px;
            padding-top: 58px;
            @include retina {
                background-image: url(#{$imgPath}bg_mv_sp@2x.jpg);
            }
        }
        &__txt {
            max-width: 1400px - 71px * 2;
            width: 100%;
            margin: 0 auto;
        }
    }
    //.myMv

    .myBlockSupport {
        $_: ".myBlockSupport";
        background-color: #566674;
        overflow: hidden;
        @include tabletPmin {
            display: flex;
            &.-support02 {
                flex-direction: row-reverse;
            }
        }
        &:not(:last-child) {
            margin-bottom: 60px;
            @include tabletP {
                margin-bottom: 20px;
            }
        }
        &__bg {
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            position: relative;
            width: calc(50% - 80px);
            min-height: 574px;
            z-index: 2;
            @include tabletP {
                width: auto;
                min-height: 270px;
            }
            @for $_i from 1 to 4 {
                $_ii: $_i; 
                @if($_ii < 10) {
                    $_ii : '0' + $_ii;
                }
                @at-root #{$_root} #{$_}.-support#{$_ii} #{$_}__bg {
                    background-image: url(#{$imgPath}bg_support#{$_ii}.jpg);
                    @include retina {
                        background-image: url(#{$imgPath}bg_support#{$_ii}@2x.jpg);
                    }
                    @include tabletP {
                        background-image: url(#{$imgPath}bg_support#{$_ii}_sp.jpg);
                        @include retina {
                            background-image: url(#{$imgPath}bg_support#{$_ii}_sp@2x.jpg);
                        }
                    }
                }
            }
        }
        &__image {
            position: absolute;
            @at-root #{$_root} #{$_}.-support01 #{$_}__image {
                @include tabletPmin {
                    width: 716px;
                    top: 80px;
                    right: -91px;
                }
                @include tabletP {
                    text-align: center;
                    top: 4px;
                    left: -28px;
                    right: -27px;
                }
            }
            @at-root #{$_root} #{$_}.-support02 #{$_}__image {
                @include tabletPmin {
                    bottom: -14px;
                    left: -61px;
                }
                @include tabletP {
                    bottom: -27px;
                    left: -13px;
                }
            }
        }
        &__detail {
            position: relative;
            padding: 61px 40px 30px 80px;
            z-index: 1;
            @include tabletPmin {
                display: flex;
                width: calc(50% + 80px);
            }
            @include tabletP {
                max-width: 500px;
                margin: 0 auto;
                padding: 29px 20px 40px;
            }
            &::after {
                content: "";
                background-position: center;
                background-repeat: no-repeat;
                background-size: auto 100%;
                position: absolute;
                z-index: 1;
                @include tabletPmin {
                    width: 220px;
                    height: 168px;
                    bottom: 0;
                    left: calc(100vw - 100% - 72px);
                    @include min(1400) {
                        left: 548px;
                    }
                }
                @include tabletP {
                    bottom: 83px;
                    right: 2px;
                    width: 140px;
                    height: 108px;
                }
            }
            @at-root #{$_root} #{$_}.-support01 #{$_}__detail {
                &::after {
                    background-image: url(#{$imgPath}num_support01.svg);
                }
            }
            @at-root #{$_root} #{$_}.-support02 #{$_}__detail {
                @include tabletPmin {
                    justify-content: flex-end;
                    padding-left: 40px;
                    padding-right: 47px;
                }
                @include tabletP {
                    padding-bottom: 80px;
                }
                &::after {
                    background-image: url(#{$imgPath}num_support02.svg);
                    @include tabletPmin {
                        left: auto;
                        right: 9px;
                    }
                    @include tabletP {
                        bottom: 123px;
                    }
                }
            }
            @at-root #{$_root} #{$_}.-support03 #{$_}__detail {
                &::after {
                    background-image: url(#{$imgPath}num_support03.svg);
                    // left: calc(100vw - 100% - 72px);
                    // @include min(1400) {
                    //     left: 548px;
                    // }
                }
            }
            @include tabletP {
                width: auto;
            }
            &-inner {
                position: relative;
                z-index: 2;
                &::before {
                    content: "";
                    background: url(#{$imgPath}title_support.svg) no-repeat 0 0;
                    background-size: contain;
                    display: block;
                    width: 109px;
                    height: 25px;
                    margin-bottom: 18px;
                    @include tabletP {
                        width: 75px;
                        height: 18px;
                        margin-bottom: 6px;
                    }
                }
            }
        }
        &__title {
            color: #fff;
            @include fz(44);
            font-family: $baseFontFamilyBold;
            font-weight: bold;
            letter-spacing: 0.04em;
            line-height: (50 / 44);
            margin-bottom: 31px;
            @include tabletP {
                @include fz(28);
                line-height: (38 / 28);
                margin-bottom: 19px;
            }
        }
        &__txt01 {
            color: #fff;
            @include fz(20);
            font-family: $baseFontFamilyBold;
            font-weight: bold;
            letter-spacing: 0.04em;
            line-height: (30 / 20);
            @include tabletP {
                @include fz(14);
                line-height: (22 / 14);
            }
            &.-indent {
                text-indent: -1em;
                padding-left: 1em;
            }
            &-strong {
                color: #E5C50C;
            }
            &-caution {
                display: block;
                @include fz(16);
                text-indent: -1em;
                margin-top: 7px;
                padding-left: 1em;
                @include tabletP {
                    @include fz(12);
                    margin-top: 2px;
                }
            }
        }
        &__txt02 {
            display: flex;
            color: #fff;
            @include fz(22);
            font-family: $baseFontFamilyBold;
            font-weight: bold;
            letter-spacing: 0.04em;
            line-height: (40 / 22);
            margin-top: 60px;
            &::before {
                content: "";
                background: url(#{$imgPath}ico_check.svg) no-repeat center;
                background-size: contain;
                flex-shrink: 0;
                width: 32px;
                height: 32px;
                margin-right: 5px;
                @include tabletP {
                    width: 24px;
                    height: 24px;
                    margin-right: 4px;
                }
            }
            @include tabletP {
                @include fz(16);
                line-height: (30 / 16);
                margin-top: 25px;
            }
            &-txt {
                &Inner {
                    background-image: linear-gradient(to top, #E5C50C 1px, transparent 1px);
                }
            }
        }
        &__link {
            background-color: #98a8c3;
            border: 1px solid #354859;
            display: inline-block;
            position: relative;
            color: #354859;
            @include fz(18);
            font-family: $baseFontFamilyExtraBold;
            font-weight: bold;
            letter-spacing: 0.04em;
            line-height: 1.4;
            text-align: center;
            text-decoration: none;
            width: 100%;
            margin: 35px 0 0 -2px;
            padding: 9px 10px 8px;
            @include tabletPmin {
                max-width: 320px;
            }
            @include tabletP {
                display: block;
                margin: 36px auto 0;
                padding: 9px 10px 8px;
            }
            &::after {
                content: "";
                border-top: 1px solid #354859;
                border-right: 1px solid #354859;
                position: absolute;
                top: 0;
                bottom: 0;
                right: 17px;
                width: 10px;
                height: 10px;
                margin: auto;
                transform: rotate(45deg);
            }
        }
        &__caution {
            display: block;
            color: #fff;
            @include fz(16);
            text-indent: -1em;
            margin-top: 10px;
            padding-left: 1em;
            @include tabletPmin {
                max-width: 634px;
                padding-right: 10px;
            }
            @include tabletP {
                position: absolute;
                top: 100%;
                left: 0;
                @include fz(12);
            }
        }
    }
    //.myBlockSupport
}