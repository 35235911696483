@charset "utf-8";

/*
makebeyond
*/
$id:makebeyond;
$cmnPath:'../img/common/';
$imgPath:'../img/#{$id}/';
body#page_#{$id} {
    .myWrapper {
        background: none;
        margin-top: -$headerHeight;
        padding-top: 0;
        padding-bottom: (103-35)+px;
        overflow: hidden;
        @include tabletP {
            margin-top: -$headerHeightSp;
            padding-bottom: 90px;
        }
    }//.myWrapper

    .myBg {
        background-size: cover;
        position: absolute;
        width: 100%;
        padding-top: 100%;
        z-index: -1;
        &.-bg01 {
            top: 0;
            background-image: url(#{$imgPath}bg01.jpg);
            height: 1220px;
            @include tabletP {
                max-height: 1800px;
                height: 390vw;
            }
        }
        &.-bg02 {
            background-image: url(#{$imgPath}bg02.jpg);
            max-height: 1920px;
            min-height: 1220px;
            width: 100vw;
        }
    }//.myBg

    .myTitlePage {
        max-width: 1273px;
        margin: 0 auto 41px;
        padding: 0 50px;
        @include tabletP {
            margin-bottom: 39px;
            padding: 0 24px 0 0;
        }
        img {
            max-width: 773px;
            width: 100%;
        }
    }//.myTitlePage

    .myMapWrapper {
        background: url(#{$imgPath}bg01.jpg) no-repeat 0 0;
        background-size: cover;
        margin-bottom: 87px;
        padding: 245px 0 90px;
        @include retina {
            background-image: url(#{$imgPath}bg01@2x.jpg);
        }
        @include tabletP {
            background-image: url(#{$imgPath}bg01_sp.jpg);
            background-size: 100%;
            padding: percentage(118px/375px) 0 0;
            @include retina {
                background-image: url(#{$imgPath}bg01_sp@2x.jpg);
            }
        }
    }//.myMapWrapper

    .myMap {
        $_: ".myMap";
        $_width: 1193px;
        $_width02: 1600;
        $_width_sp: 375px;
        background-color: #fff;
        display: block;
        align-items: center;
        max-width: 1273px;
        margin: 0 auto;
        padding: 37px 40px;
        @include tabletP {
            background: none;
            margin: 0 -#{$containerPadding}px;
            padding: 0;
        }
        &__inner {
            position: relative;
        }
        &__catch {
            display: flex;
            align-items: center;
            position: absolute;
            top: 0;
            left: 0;
            color: #fff;
            @include fz(25.5);
            font-family: $baseFontFamilyBold;
            font-weight: bold;
            letter-spacing: 0.06em;
            line-height: (58.25 / 25.5);
            text-align: left;
            text-decoration: underline;
            width: percentage(653px/$_width);
            height: 312px;
            padding: 10px percentage(100px/$_width) 20px percentage(50px/$_width);
            overflow: hidden;
            pointer-events: none;
            z-index: 2;
            @include max(1273+$containerPadding*2) {
                @include fz(21);
                height: get_vw(312, 1273+$containerPadding*2);
            }
            @include tabletP {
                position: static;
                @include fz(19.85);
                line-height: (45.33 / 19.85);
                text-decoration: none;
                width: auto;
                height: auto;
                margin-bottom: 42px;
                padding: 0 30px 0 47px;
            }
            &Inner {
                position: relative;
                text-decoration: inherit;
                z-index: 2;
                span {
                    display: inline-block;
                    text-decoration: inherit;
                }
            }
            &::after {
                content: "";
                background-color: #000;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transform-origin: 0 0;
                transform: skew(-22.6deg);
                z-index: 1;
                @include tabletP {
                    display: none;
                }
            }
        }
        &__detail {
            padding-top: percentage(732.5767px/$_width);
            @include tabletP {
                padding-top: 0;
                transition-delay: 0s !important;
            }
        }
        &__list {
            background: #fff url(#{$imgPath}bg_map.svg) no-repeat;
            background-size: 100%;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            padding: percentage(25px/$_width) 0 0 percentage((653px+12px)/$_width);
            z-index: 1;
            @include tabletP {
                background-image: url(#{$imgPath}bg_map_sp.svg);
                background-position: center bottom;
                position: relative;
                padding: 26px 0 36% 25px;
            }
        }
        &__link {
            display: block;
        }
        &__txt {
            position: relative;
            @include tabletP {
                transform-origin: left center;
                transform: scaleX(0.93);
            }
        }
        &__image {
            position: absolute;
            bottom: 0;
            right: 0;
            img {
                width: 100%;
            }
        }
        &__item {
            max-height: 24.5px;
            height: get_vw(24.5, 1193);
            @include tabletP {
                height: auto;
            }
            &.-item01 {
                @include tabletPmin {
                    max-width: 141.88px;
                    width: get_vw(141.88, $_width02);
                }
                #{$_}__image {
                    width: percentage(45.7px/$_width);
                    margin: 0 percentage(181px/$_width) percentage(113px/$_width) 0;
                    @include tabletP {
                        width: percentage(21.02px/$_width_sp);
                        margin: 0 percentage(64px/$_width_sp) percentage(44px/$_width_sp) 0;
                    }
                }
            }
            &.-item02 {
                @include tabletPmin {
                    max-width: 171.94px;
                    width: get_vw(171.94, $_width02);
                    margin-left: -10px;
                    @include max(1193) {
                        margin-left: get_vw(-10, 1193);
                    }
                }
                #{$_}__txt {
                    @include tabletP {
                        left: 0.5px;
                    }
                }
                #{$_}__image {
                    width: percentage(58px/$_width);
                    margin: 0 percentage(286px/$_width) percentage(121px/$_width) 0;
                    @include tabletP {
                        width: percentage(17.08px/$_width_sp);
                        margin: 0 percentage(104px/$_width_sp) percentage(51px/$_width_sp) 0;
                    }
                }
            }
            &.-item03 {
                @include tabletPmin {
                    max-width: 187.81px;
                    width: get_vw(187.81, $_width02);
                    margin-left: -20px;
                    @include max(1193) {
                        margin-left: get_vw(-20, 1193);
                    }
                }
                #{$_}__txt {
                    @include tabletP {
                        left: -1.5px;
                    }
                }
                #{$_}__image {
                    width: percentage(131.61px/$_width);
                    margin: 0 percentage(22px/$_width) percentage(48px/$_width) 0;
                    @include tabletP {
                        width: percentage(38.75px/$_width_sp);
                        margin: 0 percentage(25px/$_width_sp) percentage(37px/$_width_sp) 0;
                    }
                }
            }
            &.-item04 {
                @include tabletPmin {
                    max-width: 146.94px;
                    width: get_vw(146.94, $_width02);
                    margin-left: -30px;
                    @include max(1193) {
                        margin-left: get_vw(-30, 1193);
                    }
                }
                #{$_}__txt {
                    @include tabletP {
                        left: 1px;
                    }
                }
                #{$_}__image {
                    width: percentage(71.05px/$_width);
                    margin: 0 percentage(265px/$_width) percentage(192px/$_width) 0;
                    @include tabletP {
                        width: percentage(25.31px/$_width_sp);
                        margin: 0 percentage(97px/$_width_sp) percentage(76px/$_width_sp) 0;
                    }
                }
            }
            &.-item05 {
                @include tabletPmin {
                    max-width: 254.83px;
                    width: get_vw(254.83, $_width02);
                    margin-left: -40px;
                    @include max(1193) {
                        margin-left: get_vw(-40, 1193);
                    }
                }
                #{$_}__txt {
                    @include tabletP {
                        left: -1px;
                    }
                }
                #{$_}__image {
                    width: percentage(18.13px/$_width);
                    margin: 0 percentage(267px/$_width) percentage(114px/$_width) 0;
                    @include tabletP {
                        width: percentage(16px/$_width_sp);
                        margin: 0 percentage(92px/$_width_sp) percentage(36px/$_width_sp) 0;
                    }
                }
            }
            &.-item06 {
                @include tabletPmin {
                    max-width: 232.67px;
                    width: get_vw(232.67, $_width02);
                    margin-left: -50px;
                    @include max(1193) {
                        margin-left: get_vw(-50, 1193);
                    }
                }
                #{$_}__txt {
                    @include tabletP {
                        left: -0.5px;
                    }
                }
                #{$_}__image {
                    width: percentage(51.52px/$_width);
                    margin: 0 percentage(220px/$_width) percentage(149px/$_width) 0;
                    @include tabletP {
                        width: percentage(16.58px/$_width_sp);
                        margin: 0 percentage(82px/$_width_sp) percentage(54px/$_width_sp) 0;
                    }
                }
            }
            &.-item07 {
                @include tabletPmin {
                    max-width: 159.52px;
                    width: get_vw(159.52, $_width02);
                    margin-left: -60px;
                    @include max(1193) {
                        margin-left: get_vw(-60, 1193);
                    }
                }
                #{$_}__txt {
                    @include tabletP {
                        left: 0.5px;
                    }
                }
                #{$_}__image {
                    width: percentage(71.08px/$_width);
                    margin: 0 percentage(366px/$_width) percentage(92px/$_width) 0;
                    @include tabletP {
                        width: percentage(22.44px/$_width_sp);
                        margin: 0 percentage(127px/$_width_sp) percentage(38px/$_width_sp) 0;
                    }
                }
            }
            &.-item08 {
                @include tabletPmin {
                    max-width: 100.58px;
                    width: get_vw(100.58, $_width02);
                    margin-left: -70px;
                    @include max(1193) {
                        margin-left: get_vw(-70, 1193);
                    }
                }
                #{$_}__txt {
                    @include tabletP {
                        left: 1.5px;
                    }
                }
                #{$_}__image {
                    width: percentage(77.2px/$_width);
                    margin: 0 percentage(320px/$_width) percentage(180px/$_width) 0;
                    @include tabletP {
                        width: percentage(28.55px/$_width_sp);
                        margin: 0 percentage(113px/$_width_sp) percentage(70px/$_width_sp) 0;
                    }
                }
            }
            &.-item09 {
                @include tabletPmin {
                    max-width: 134.91px;
                    width: get_vw(134.91, $_width02);
                    margin-left: -80px;
                    @include max(1193) {
                        margin-left: get_vw(-80, 1193);
                    }
                }
                #{$_}__txt {
                    @include tabletP {
                        left: 1px;
                    }
                }
                #{$_}__image {
                    width: percentage(33.84px/$_width);
                    margin: 0 percentage(176px/$_width) percentage(300px/$_width) 0;
                    @include tabletP {
                        width: percentage(16px/$_width_sp);
                        margin: 0 percentage(67px/$_width_sp) percentage(106px/$_width_sp) 0;
                    }
                }
            }
            &.-item10 {
                @include tabletPmin {
                    max-width: 143.05px;
                    width: get_vw(143.05, $_width02);
                    margin-left: -90px;
                    @include max(1193) {
                        margin-left: get_vw(-90, 1193);
                    }
                }
                #{$_}__image {
                    width: percentage(170.64px/$_width);
                    margin: 0 percentage(159px/$_width) percentage(501px/$_width) 0;
                    @include tabletP {
                        width: percentage(50.23px/$_width_sp);
                        margin: 0 percentage(66px/$_width_sp) percentage(171px/$_width_sp) 0;
                    }
                }
            }
            &.-item11 {
                @include tabletPmin {
                    max-width: 180.83px;
                    width: get_vw(180.83, $_width02);
                    margin-left: -100px;
                    @include max(1193) {
                        margin-left: get_vw(-100, 1193);
                    }
                }
                #{$_}__txt {
                    @include tabletP {
                        left: 2px;
                    }
                }
                #{$_}__image {
                    width: percentage(128.52px/$_width);
                    margin: 0 percentage(664px/$_width) percentage(215px/$_width) 0;
                    @include tabletP {
                        width: percentage(37.84px/$_width_sp);
                        margin: 0 percentage(214px/$_width_sp) percentage(87px/$_width_sp) 0;
                    }
                }
            }
            &.-item12 {
                @include tabletPmin {
                    max-width: 242.5px;
                    width: get_vw(242.5, $_width02);
                    margin-left: -110px;
                    @include max(1193) {
                        margin-left: get_vw(-110, 1193);
                    }
                }
                #{$_}__txt {
                    @include tabletP {
                        left: -2px;
                    }
                }
                #{$_}__image {
                    width: percentage(47.36px/$_width);
                    margin: 0 percentage(704px/$_width) percentage(19px/$_width) 0;
                    @include tabletP {
                        width: percentage(24.2px/$_width_sp);
                        margin: 0 percentage(226px/$_width_sp) percentage(17px/$_width_sp) 0;
                    }
                }
            }
        }
    }//.myMap

    .myList {
        @include tabletPmin {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }
    }

    .myItem {
        $_item: ".myItem";
        position: relative;
        @include tabletP {
            margin-bottom: 56px;
            padding: 30px 0;
        }
        &__wrapper {
            position: relative;
        }
        &__num {
            @include tabletP {
                text-align: right;
                margin-bottom: 20px;
            }
            img {
                @include tabletP {
                    width: 80px;
                }
            }
        }
        &__title {
            margin-bottom: 20px;
        }
        &__name {
            @include fz(24);
            font-family: $baseFontFamilyBold;
            font-weight: bold;
            line-height: (42 / 24);
            @include tabletP {
                @include fz(20);
                margin-bottom: 20px;
            }
        }
        &__catch {
            @include fz(19);
            font-family: $baseFontFamilyBold;
            font-weight: 600;
            line-height: (30 / 19);
            @include tabletP {
                @include fz(17);
                margin-bottom: 30px;
            }
        }
        &__txt {
            @include fz(19);
            font-family: $fontMincho;
            font-weight: 500;
            line-height: (30 / 19);
            @include tabletP {
                @include fz(17);
            }
            & + #{$_item}__image,
            & + #{$_item}-imagebox {
                @include tabletP {
                    margin-top: 24px;
                }
            }
        }
        &__image {
            position: relative;
            & + #{$_item}__image {
                margin-top: 36px;
                @include tabletP {
                    margin-top: 24px;
                }
            }
            & + #{$_item}-imagebox {
                @include tabletP {
                    margin-top: 24px;
                }
            }
            @include tabletP {
                text-align: center;
            }
            figcaption {
                position: absolute;
                bottom: 11px;
                right: 30px;
                color: #fff;
                @include fz(18);
                font-family: $baseFontFamilyBold;
                font-weight: bold;
                line-height: 1.4;
            }
        }
        &-imagebox {
            & + #{$_item}__image,
            & + #{$_item}-imagebox {
                @include tabletP {
                    margin-top: 24px;
                }
            }
            &__detail {
                margin-top: 10px;
            }
            &__title,
            &__txt {
                @include tabletP {
                    @include fz(12);
                }
            }
            &__title {
                @include tabletP {
                    font-family: $baseFontFamilyBold;
                    font-weight: bold;
                }
            }
            &__source {
                color: #231815;
                @include fz(14);
                font-weight: 500;
                line-height: (16.8 / 14);
                @include tabletP {
                    @include fz(10);
                }
            }
        }

        // 各アイテムの対応（主にPC時の表示対応）
        &.-item01 {
            @include tabletPmin {
                margin-bottom: 112px;
            }
            #{$_item}__num {
                @include tabletPmin {
                    margin-bottom: 37px;
                }
            }
            #{$_item}__title {
                @include tabletPmin {
                    margin-bottom: 12px;
                }
            }
            #{$_item}__name {
                @include tabletPmin {
                    @include fz(48);
                    line-height: (84 / 48);
                    margin-bottom: 21px;
                    padding-left: 6px;
                }
            }
            #{$_item}__catch {
                @include tabletPmin {
                    margin-bottom: 41px;
                    padding-left: 6px;
                }
            }
            #{$_item}__txt {
                @include tabletPmin {
                    margin-bottom: 33px;
                    padding-left: 6px;
                }
                @include tabletP {
                    margin-bottom: 24px;
                }
            }
            #{$_item}__image {
                &.-image01 {
                    @include tabletPmin {
                        text-align: right;
                        margin-bottom: 76px;
                    }
                }
            }
            #{$_item}-imagebox {
                @include tabletPmin {
                    display: flex;
                }
                @include tabletP {
                    color: #231815;
                }
                #{$_item}__image {
                    @include tabletPmin {
                        flex-shrink: 0;
                    }
                }
                #{$_item}-imagebox__detail {
                    @include tabletPmin {
                        width: 100%;
                    }
                }
                #{$_item}-imagebox__title,
                #{$_item}-imagebox__txt {
                    @include tabletPmin {
                        color: #231815;
                        @include fz(18);
                        line-height: (31.5 / 18);
                    }
                }
                #{$_item}-imagebox__title {
                    @include tabletPmin {
                        font-family: $baseFontFamilyBold;
                        font-weight: bold;
                    }
                }
                #{$_item}-imagebox__txt {
                    @include tabletPmin {
                        font-weight: 500;
                    }
                }
                &.-image02 {
                    @include tabletPmin {
                        align-items: flex-end;
                        margin-bottom: 53px;
                    }
                    #{$_item}__image {
                        @include tabletPmin {
                            width: percentage(600px/1000px);
                        }
                    }
                    #{$_item}-imagebox__detail {
                        @include tabletPmin {
                            padding: 0 0 9px 14px;
                        }
                    }
                }
                &.-image03 {
                    @include tabletPmin {
                        justify-content: flex-end;
                        margin-bottom: -113px;
                    }
                    #{$_item}__image {
                        @include tabletPmin {
                            order: 1;
                            max-width: 304px;
                            width: percentage(350px/1000px);
                        }
                    }
                    #{$_item}-imagebox__detail {
                        @include tabletPmin {
                            max-width: 408px;
                            padding: 40px 10px 0 0;
                        }
                    }
                }
                &.-image04 {
                    @include tabletPmin {
                        align-items: flex-end;
                    }
                    #{$_item}__image {
                        @include tabletPmin {
                            width: percentage(535px/1000px);
                        }
                    }
                    #{$_item}-imagebox__detail {
                        @include tabletPmin {
                            padding: 0 0 9px 23px;
                        }
                    }
                    #{$_item}-imagebox__source {
                        @include tabletPmin {
                            @include fz(12);
                            line-height: (14.4 / 12);
                            margin-top: 23px;
                        }
                    }
                }
            }
        }//.-item01

        &.-item02 {
            color: #fff;
            @include tabletPmin {
                margin-bottom: 112px;
            }
            #{$_item}__wrapper {
                @include tabletPmin {
                    padding-top: 108px;
                }
            }
            #{$_item}__num {
                @include tabletPmin {
                    position: absolute;
                    top: 112px;
                    right: 75px;
                }
            }
            #{$_item}__title {
                @include tabletPmin {
                    margin-bottom: 29px;
                }
            }
            #{$_item}__name {
                @include tabletPmin {
                    @include fz(48);
                    line-height: (84 / 48);
                    margin-bottom: 38px;
                    padding-left: 1px;
                }
            }
            #{$_item}__catch {
                @include tabletPmin {
                    margin-bottom: 59px;
                    padding-left: 1px;
                }
            }
            #{$_item}__txt {
                @include tabletPmin {
                    margin-bottom: 62px;
                    padding-left: 1px;
                }
                @include tabletP {
                    margin-bottom: 24px;
                }
            }
            #{$_item}__image {
                &.-image01 {
                    @include tabletPmin {
                        text-align: right;
                        margin-bottom: 92px;
                    }
                }
            }
            #{$_item}-imagebox {
                @include tabletPmin {
                    display: flex;
                }
                @include tabletP {
                    color: #231815;
                }
                #{$_item}__image {
                    @include tabletPmin {
                        flex-shrink: 0;
                    }
                }
                #{$_item}-imagebox__detail {
                    @include tabletPmin {
                        width: 100%;
                    }
                }
                #{$_item}-imagebox__title,
                #{$_item}-imagebox__txt {
                    @include tabletPmin {
                        color: #231815;
                        @include fz(18);
                        line-height: (31.5 / 18);
                    }
                }
                #{$_item}-imagebox__title {
                    @include tabletPmin {
                        font-family: $baseFontFamilyBold;
                        font-weight: bold;
                    }
                }
                #{$_item}-imagebox__txt {
                    @include tabletPmin {
                        font-weight: 500;
                    }
                }
                &.-image02 {
                    @include tabletPmin {
                        align-items: flex-end;
                        margin-bottom: 67px;
                    }
                    #{$_item}__image {
                        @include tabletPmin {
                            width: percentage(670px/1366px);
                        }
                    }
                    #{$_item}-imagebox__detail {
                        @include tabletPmin {
                            padding: 0 0 66px 34px;
                        }
                    }
                }
                &.-image03 {
                    @include tabletPmin {
                        align-items: flex-end;
                        margin-bottom: 40px;
                    }
                    #{$_item}__image {
                        @include tabletPmin {
                            order: 1;
                            width: percentage(720px/1366px);
                        }
                    }
                    #{$_item}-imagebox__detail {
                        @include tabletPmin {
                            text-align: right;
                            max-width: 558px;
                            padding: 0 12px 26px 0;
                        }
                    }
                }
                &.-image04 {
                    @include tabletPmin {
                        justify-content: flex-end;
                        align-items: flex-end;
                    }
                    #{$_item}__image {
                        @include tabletPmin {
                            width: percentage(609px/1366px);
                        }
                    }
                    #{$_item}-imagebox__detail {
                        @include tabletPmin {
                            max-width: 563px;
                            padding: 0 0 22px 20px;
                        }
                    }
                    #{$_item}-imagebox__source {
                        @include tabletPmin {
                            @include fz(12);
                            line-height: (14.4 / 12);
                            margin-top: 28px;
                        }
                    }
                }
            }
        }//.-item02

        &.-item03 {
            color: #fff;
            @include tabletPmin {
                width: 50%;
                padding: 43px 20px 0 0;
            }
            &::before {
                content: "";
                background-color: #566674;
                position: absolute;
                top: 0;
                right: 0;
                width: 100vw;
                height: 81.2%;
                z-index: -1;
                @include tabletP {
                    right: -#{$containerPadding}px;
                    height: 100%;
                }
            }
            #{$_item}__wrapper {
                @include tabletPmin {
                    padding-top: 108px;
                }
            }
            #{$_item}__num {
                @include tabletPmin {
                    text-align: right;
                    margin-bottom: 55px;
                    padding-right: 13px;
                }
            }
            #{$_item}__title {
                @include tabletPmin {
                    width: 60%;
                    margin-bottom: 30px;
                }
            }
            #{$_item}__name {
                @include tabletPmin {
                    @include fz(24);
                    line-height: (42 / 24);
                    margin-bottom: 18px;
                    padding-left: 1px;
                }
            }
            #{$_item}__catch {
                @include tabletPmin {
                    margin-bottom: 11px;
                    padding-left: 1px;
                }
            }
            #{$_item}__txt {
                @include tabletPmin {
                    margin-bottom: 42px;
                    padding-left: 1px;
                }
            }
        }//.-item03

        &.-item04 {
            @include tabletPmin {
                width: calc(50% - 43px);
                padding: 165px 0 0;
            }
            #{$_item}__wrapper {
                @include tabletPmin {
                    padding-top: 108px;
                }
            }
            #{$_item}__num {
                @include tabletPmin {
                    position: relative;
                    top: 97px;
                    text-align: right;
                    margin-bottom: 55px;
                    padding-right: 28px;
                }
            }
            #{$_item}__title {
                @include tabletPmin {
                    width: 60%;
                    margin-bottom: 23px;
                }
            }
            #{$_item}__name {
                @include tabletPmin {
                    @include fz(24);
                    line-height: (42 / 24);
                    margin-bottom: 13px;
                    padding-left: 1px;
                }
            }
            #{$_item}__catch {
                @include tabletPmin {
                    margin-bottom: 22px;
                    padding-left: 1px;
                }
            }
            #{$_item}__txt {
                @include tabletPmin {
                    margin-bottom: 13px;
                    padding-left: 5px;
                }
            }
        }//.-item04

        &.-item05 {
            @include tabletPmin {
                width: calc(50% - 20px);
                padding-top: 15px;
            }
            #{$_item}__wrapper {
                @include tabletPmin {
                }
            }
            #{$_item}__num {
                @include tabletPmin {
                    position: relative;
                    top: 45px;
                    text-align: right;
                    padding-right: 45px;
                }
            }
            #{$_item}__title {
                @include tabletPmin {
                    width: 56%;
                    margin-bottom: 19px;
                }
            }
            #{$_item}__name {
                @include tabletPmin {
                    @include fz(24);
                    line-height: (42 / 24);
                    margin-bottom: 8px;
                    padding-left: 1px;
                }
            }
            #{$_item}__catch {
                @include tabletPmin {
                    margin-bottom: 32px;
                    padding-left: 1px;
                }
            }
            #{$_item}__txt {
                @include tabletPmin {
                    margin-bottom: 66px;
                }
            }
        }//.-item05

        &.-item06 {
            color: #fff;
            @include tabletPmin {
                width: calc(50% + -9px);
                margin-top: 92px;
                padding: 51px 0 0 13px;
            }
            &::before {
                content: "";
                background-color: #743930;
                position: absolute;
                top: 0;
                left: 0;
                width: 100vw;
                height: 92%;
                z-index: -1;
                @include tabletP {
                    left: -#{$containerPadding}px;
                    height: 100%;
                }
            }
            #{$_item}__wrapper {
                @include tabletPmin {
                    padding-top: 108px;
                }
            }
            #{$_item}__num {
                @include tabletPmin {
                    text-align: right;
                    margin-bottom: -50px;
                    padding-right: 12px;
                }
            }
            #{$_item}__title {
                @include tabletPmin {
                    width: 60%;
                    margin-bottom: 30px;
                    padding-left: 24px;
                }
            }
            #{$_item}__name {
                @include tabletPmin {
                    @include fz(24);
                    line-height: (42 / 24);
                    margin-bottom: 10px;
                    padding-left: 25px;
                }
            }
            #{$_item}__catch {
                @include tabletPmin {
                    margin-bottom: 46px;
                    padding-left: 25px;
                }
            }
            #{$_item}__txt {
                @include tabletPmin {
                    margin-bottom: 35px;
                    padding-left: 23px;
                }
            }
        }//.-item06

        &.-item07 {
            @include tabletPmin {
                width: 100%;
                padding-top: 284px;
            }
            #{$_item}__wrapper {
                @include tabletPmin {
                }
            }
            #{$_item}__num {
                @include tabletPmin {
                    position: relative;
                    top: 32px;
                    text-align: right;
                    padding-right: 124px;
                }
            }
            #{$_item}__title {
                @include tabletPmin {
                    margin-bottom: 29px;
                    padding-left: 17px;
                }
            }
            #{$_item}__name {
                @include tabletPmin {
                    @include fz(21);
                    line-height: (36.75 / 21);
                    margin-bottom: 43px;
                    padding-left: 18px;
                }
            }
            #{$_item}__catch {
                @include tabletPmin {
                    margin-bottom: 29px;
                    padding-left: 23px;
                }
            }
            #{$_item}__txt {
                @include tabletPmin {
                    margin-bottom: 39px;
                    padding-left: 17px;
                }
            }
        }//.-item07

        &.-item08 {
            @include tabletPmin {
                width: 100%;
                padding-top: 176px;
                padding-right: calc(50%);
            }
            #{$_item}__wrapper {
                @include tabletPmin {
                }
            }
            #{$_item}__num {
                @include tabletPmin {
                    position: relative;
                    top: 40px;
                    text-align: right;
                }
            }
            #{$_item}__title {
                @include tabletPmin {
                    width: 70%;
                    margin-bottom: 15px;
                    padding-left: 27px;
                }
            }
            #{$_item}__name {
                @include tabletPmin {
                    @include fz(21);
                    line-height: (36.75 / 21);
                    margin-bottom: 11px;
                    padding-left: 20px;
                }
            }
            #{$_item}__catch {
                @include tabletPmin {
                    margin-bottom: 43px;
                    padding-left: 23px;
                }
            }
            #{$_item}__txt {
                @include tabletPmin {
                    padding-left: 7px;
                }
            }
            #{$_item}-imagebox {
                @include tabletPmin {
                    position: absolute;
                    top: 239px;
                    right: 0;
                    max-width: 563px;
                    width: calc(50% - 40px);
                }
                &__detail {
                    @include tabletPmin {
                        margin-top: 11px;
                        padding-left: 4px;
                    }
                }
                &__txt {
                    @include tabletPmin {
                        color: #231815;
                        @include fz(11);
                        font-family: $baseFontFamilyBold;
                        font-weight: 600;
                        line-height: (19.25 / 11);
                    }
                }
            }
        }//.-item08

        &.-item09 {
            @include tabletPmin {
                width: 100%;
                margin-top: 86px;
                padding: 97px calc(50% - 10px) 100px 98px;
            }
            &::before {
                content: "";
                background-color: rgba(#C6B198, .2);
                position: absolute;
                top: 0;
                left: 0;
                width: 100vw;
                height: 100%;
                z-index: -1;
                left: -#{$containerPadding}px;
            }
            #{$_item}__wrapper {
                @include tabletPmin {
                }
            }
            #{$_item}__num {
                @include tabletPmin {
                    position: relative;
                    top: 37px;
                    text-align: right;
                }
            }
            #{$_item}__title {
                @include tabletPmin {
                    width: 60%;
                    margin-bottom: 13px;
                    padding-left: 5px;
                }
            }
            #{$_item}__name {
                @include tabletPmin {
                    @include fz(21);
                    line-height: (36.75 / 21);
                    margin-bottom: 20px;
                }
            }
            #{$_item}__catch {
                @include tabletPmin {
                    margin-bottom: 37px;
                }
            }
            #{$_item}__txt {
                @include tabletPmin {
                    padding-left: 5px;
                }
            }
            #{$_item}__image {
                @include tabletPmin {
                    position: absolute;
                    top: 145px;
                    right: 0;
                    max-width: 558px;
                    width: calc(50% - 40px);
                }
            }
        }//.-item09

        &.-item10 {
            @include tabletPmin {
                width: 100%;
                padding-top: 101px;
            }
            #{$_item}__wrapper {
                @include tabletPmin {
                }
            }
            #{$_item}__num {
                @include tabletPmin {
                    position: relative;
                    top: 129px;
                    text-align: right;
                    max-width: 615px;
                    width: 40.6%;
                    padding-right: 2.5%;
                }
            }
            #{$_item}__title {
                @include tabletPmin {
                    margin-bottom: 31px;
                    padding-left: 11px;
                    max-width: 615px;
                    width: 40.6%;
                }
            }
            #{$_item}__name {
                @include tabletPmin {
                    @include fz(21);
                    line-height: (36.75 / 21);
                    margin-bottom: 130px;
                    padding-left: 7px;
                }
            }
            #{$_item}__catch {
                @include tabletPmin {
                    margin-bottom: 30px;
                    padding-left: 11px;
                }
            }
            #{$_item}__txt {
                @include tabletPmin {
                    padding-right: 8.5%;
                }
            }
            #{$_item}__image {
                @include tabletPmin {
                    position: absolute;
                    top: 122px;
                    right: 6.6%;
                    max-width: 624px;
                    width: calc(50%);
                }
            }
        }//.-item10

        &.-item11 {
            @include tabletPmin {
                width: 100%;
                margin-top: 86px;
                padding: 39px 0 88px 0;
            }
            &::before {
                content: "";
                background-color: rgba(#EFF1F4, .5);
                position: absolute;
                top: 0;
                right: 0;
                width: 100vw;
                height: 100%;
                z-index: -1;
                right: -#{$containerPadding}px;
            }
            #{$_item}__wrapper {
                @include tabletPmin {
                }
            }
            #{$_item}__num {
                @include tabletPmin {
                    position: relative;
                    top: 38px;
                    width: 593px;
                    text-align: right;
                }
            }
            #{$_item}__title {
                @include tabletPmin {
                    margin-bottom: 6px;
                    padding-left: 9px;
                }
            }
            #{$_item}__name {
                @include tabletPmin {
                    @include fz(21);
                    line-height: (36.75 / 21);
                    padding-left: 9px;
                }
            }
            #{$_item}__catch {
                @include tabletPmin {
                    margin-top: -10px;
                    margin-bottom: 19px;
                    padding-left: calc(50% + 10px);
                    padding-right: 20px;
                }
            }
            #{$_item}__txt {
                @include tabletPmin {
                    padding-left: calc(50% + 10px);
                    padding-right: 20px;
                }
            }
            #{$_item}__image {
                @include tabletPmin {
                    position: absolute;
                    top: 178px;
                    left: 0;
                    max-width: 580px;
                    width: calc(50% - 20px);
                }
            }
        }//.-item11

        &.-item12 {
            @include tabletPmin {
                width: 100%;
                padding-top: 83px;
            }
            @include tabletP {
                margin-bottom: 0;
                padding-bottom: 0;
            }
            #{$_item}__wrapper {
                @include tabletPmin {
                }
            }
            #{$_item}__num {
                @include tabletPmin {
                    position: relative;
                    top: 47px;
                    max-width: 768px;
                    text-align: right;
                }
            }
            #{$_item}__title {
                @include tabletPmin {
                    width: 80%;
                    margin-bottom: 14px;
                    padding-left: 3px;
                }
            }
            #{$_item}__name {
                @include tabletPmin {
                    @include fz(21);
                    line-height: (36.75 / 21);
                    padding-left: 2px;
                    margin-bottom: 21px;
                }
            }
            #{$_item}__catch {
                @include tabletPmin {
                    margin-bottom: 27px;
                    padding-right: calc(54.9% + -20px);
                    padding-left: 4px;
                }
            }
            #{$_item}__txt {
                @include tabletPmin {
                    padding-right: calc(54.9% + -20px);
                    padding-left: 3px;
                }
            }
            #{$_item}-imagebox {
                @include tabletPmin {
                    position: absolute;
                    top: 244px;
                    right: 1.5%;
                    max-width: 615px;
                    width: calc(50% - 10px);
                }
                &__detail {
                    @include tabletPmin {
                        margin-top: 37px;
                        padding-right: 13.5%;
                    }
                }
                &__source {
                    @include tabletPmin {
                        color: #231815;
                        @include fz(14);
                        font-weight: bold;
                        text-align: right;
                    }
                }
            }
        }//.-item12
    }//.myItem
}