@charset "utf-8";

/*
voice
*/
$id:voice;
$cmnPath:'../img/common/';
$imgPath:'../img/#{$id}/';
body#page_#{$id} {

    $_root:&;
    $_myColor:#293B5F;


    .myWrapper{
        padding-top: 133px;
        padding-bottom: (103-35)+px;
        overflow: hidden;
        @include tabletP{
            padding-top: 44px;
            padding-bottom: 90px;
        }

    }

    .myNav{
        @include tabletPmin{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        &__item{
            display: block;
            background-color: nth($_myColor,1);
            text-decoration: none;
            @include fz(18);
            line-height: 1.2;

            &:hover{
                opacity: 0.8;
            }


            @include tabletPmin{
                width: 32.5%;
                margin-top: 1.5%;
            }

            @include tabletP{
                @include fz(16);
                &:not(:last-child){
                    margin-bottom: 20px;
                }
            }
        }

        &__image{
            text-align: center;

            img{
                @include tabletP{
                    width: 100%;
                }
            }
        }

        &__detail{
            padding: 30px 20px;
            color:#fff;

        }

        &__title{
            margin-bottom: 15px;

        }

        &__txt{

        }

    }//.myNav




    @keyframes catchLeft01 {
        0% {
            width: 0;
            left: 100%;
            opacity: 0;
        }

        100% {
            width: 100%;
            left:0;
            opacity: 1;
        }
    }

    @keyframes catchLeft02{
        0% {
            height: 0;
            opacity: 0;
        }

        100% {
            height: 100%;
            opacity: 1;
        }
    }


    @keyframes catchRight01{
        0% {
            height: 0;
            bottom: 100%;
            opacity: 0;
        }

        100% {
            height: 100%;
            bottom: 0;
            opacity: 1;
        }
    }

    @keyframes catchRight02{
        0% {
            width: 0;
            opacity: 0;
        }

        100% {
            width: 100%;
            opacity: 1;
        }
    }

    @keyframes catchFadeIn{
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }



    .myBlockLead{
        position: relative;
        margin-bottom: 100px;

        @include tabletP{
            margin-bottom: 45px;
        }

        $_lead:'.myBlockLead';

        $_load:1;
        $_spW:0.2;
        $_spH:0.2;
        $_anime:$easeOutQuart;

        &__inner{
            opacity: 0;
            padding: 50px 10px 50px 50px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            @at-root #{$_root} #{$_lead}.aos-animate #{$_lead}__inner{
                @include animation(catchFadeIn,2s,ease,1,forwards,#{$_load + $_spW*1 + $_spH*1}s);
            }

            @include tabletP{
                padding: 30px 30px;
            }

            @include sphoneP{
                @include fz(18);

            }


        }

        &__image{
            text-align: center;
            @include tabletPmin{
                width: 34.3%;
            }

            @include tabletP{
                width: 100%;
                order:2;
            }
        }

        &__detail{
            color:nth($_myColor,1);
            line-height: 1.5;
            @include tabletPmin{
                flex:1;
                padding-left: 36px;
            }

            @include tabletP{
                width: 100%;
                order:1;
                margin-bottom: 20px;
            }
        }

        &__name{
            margin-bottom: 20px;
            @include fz(21);
            letter-spacing: 0;
            @include tabletP{
                margin-bottom: 15px;
                @include fz(16);
            }

        }

        &__txt{
            @include fz(25);
            font-family: $baseFontFamilyBold;
            @include tabletP{
                @include fz(14);
            }

        }

        &-frame{
            $_:'.myBlockLead-frame';
            position: absolute;


            &.-left01,
            &.-right02
            {
                width: 200px;
                height: 8px;
                @include tabletP{
                    width: get_vw(75,375);
                    height: 3px;
                }
            }

            &.-left01 #{$_}__item,
            &.-right02 #{$_}__item
            {
                height: 100%;
            }

            &.-left02,
            &.-right01
            {
                width: 8px;
                height: 90px;
                @include tabletP{
                    width: 3px;
                    height: get_vw(33,375);
                }

            }

            &.-left02 #{$_}__item,
            &.-right01 #{$_}__item
            {
                width: 100%;
            }

            &.-left01 ,
            &.-left02 ,{
                top: 0;
                left: 0;
            }

            &.-right01 ,
            &.-right02 ,{
                right: 0;
                bottom: 0;
            }

            &.-left01 #{$_}__item,
            &.-left02 #{$_}__item,{
                top: 0;
                left: 0;
            }

            &.-right01 #{$_}__item,
            &.-right02 #{$_}__item,{
                right: 0;
                bottom: 0;
            }

            @at-root #{$_root} #{$_lead}.aos-animate #{$_}.-left01 #{$_}__item{
                @include animation(catchLeft01,#{$_spW}s,#{$_anime},1,forwards,#{$_load}s);
            }

            @at-root #{$_root} #{$_lead}.aos-animate #{$_}.-left02 #{$_}__item{
                @include animation(catchLeft02,#{$_spH}s,#{$_anime},1,forwards,#{$_load + $_spW}s);
            }

            @at-root #{$_root} #{$_lead}.aos-animate #{$_}.-right01 #{$_}__item{
                @include animation(catchRight01,#{$_spH}s,#{$_anime},1,forwards,#{$_load + $_spW + $_spH}s);
            }

            @at-root #{$_root} #{$_lead}.aos-animate #{$_}.-right02 #{$_}__item{
                @include animation(catchRight02,#{$_spW}s,#{$_anime},1,forwards,#{$_load + $_spW + $_spH + $_spH}s);
            }

            &__item{
                display: block;
                background-color: #293B5F;
                position: absolute;
            }


        }


    }//.modTxtCatch


    .myTitle01{
        position: relative;
        max-width: 480px;
        padding: 10px 0 30px 32px;
        margin-bottom: 50px;
        color:nth($_myColor,1);
        @include fz(21);
        font-family: $baseFontFamilyBold;
        @include tabletP{
            margin-bottom: 20px;
            @include fz(16);
        }

        &::before,
        &::after{
            content:"";
            background-color: nth($_myColor,1);
            position: absolute;

        }

        &::before{
            top: 0;
            bottom: 0;
            left: 10px;
            width: 1px;

        }

        &::after{
            left: 0;
            right: 0;
            bottom: 18px;
            height: 1px;

        }


        &__inner{

        }

    }


    .myBlockDetail01{
        margin-bottom: 50px;

        $_:'.myBlockDetail01';

        &__body{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            @include tabletPmin{
                margin-left: 36px;
            }

            & + #{$_}__body{
                margin-top: 50px;
                @include tabletP{
                    margin-top: 20px;
                }
            }

            &.-image{

                #{$_}__image{
                    @include tabletPmin{
                        width: 47.3%;
                    }
                }

            }
        }

        &__detail{
            @include fz(18);
            line-height: 1.5;

            @include tabletPmin{
                flex:1;
                padding-right: 72px;
            }

            @include tabletP{
                order:1;
                width: 100%;
                margin-bottom: 20px;
                @include fz(16);
            }

            &:only-child{
                flex:none;
                width: 100%;
                padding-right: 0;
            }

            strong{
                font-family: $baseFontFamilyBold;
            }

        }

        &__image{
            width: 30.1%;
            text-align: center;

            &.-large{
                @include tabletPmin{
                    width: 47.5%;
                }
            }

            @include tabletP{
                order:2;
                width: 100%;
                margin-bottom: 20px;
            }
        }

    }//.myBlockDetail01

    .myPager{
        position: relative;
        margin-top: 280px;


        @include tabletPmin{
            @include clearfix;
        }

        @include tabletP{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            margin-top: 60px;
        }

        &.-first{
            justify-content: flex-end;
        }


        &__prev,
        &__next{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            position: relative;
            height: 55px;
            text-decoration: none;
            @include fz(21);
            color:nth($_myColor,1);

            &::before{
                content:"";
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto 0;
                background-image: url(../img/voice/arrow.svg);
                background-repeat: no-repeat;
                background-size: 55px 55px;
                width: 55px;
                height: 55px;
                @include tabletP{
                    background-size: 30px 30px;
                    width: 30px;
                    height: 30px;
                }
            }

            @include tabletP{
                @include fz(18);
            }

            &:hover{
                opacity: 0.8;
            }


        }

        &__prev{
            padding-left: 70px;
            background-position: left center;

            &::before{
                left: 0;
                transform: scale(-1, 1);
            }

            @include tabletPmin{
                float: left;
            }
            @include tabletP{
                padding-left: 40px;
            }
        }

        &__next{
            padding-right: 70px;
            background-position: right center;
            text-align: right;

            &::before{
                right: 0;
            }

            @include tabletPmin{
                float: right;
            }

            @include tabletP{
                padding-right: 40px;
            }

        }

        &__list{
            display: block;
            background-color:nth($_myColor,1);
            border:solid 2px nth($_myColor,1);
            width: 327px;
            transition: background-color 0.3s ease;
            color:#fff;
            @include fz(21);
            text-align: center;
            font-family: $baseFontFamilyBold;
            text-decoration: none;

            @include tabletP{
                margin-top: 10px;
            }

            &:hover{
                background-color: #fff;
                color:nth($_myColor,1);
            }


            @include tabletPmin{
                height: 55px;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                margin-right: auto;
                margin-left: auto;
            }
            @include tabletP{
                width: 100%;
                padding: 8px 10px;
                @include fz(18);
            }

        }
    }


}
