@charset "utf-8";

/*
request_detail
*/
$id:request_detail;
$cmnPath:'../img/common/';
$imgPath:'../img/#{$id}/';
body#page_#{$id} {
    $_root: &;
    .gHeader {
        background-color: #7E7E7E;
    }

    .myWrapper {
        $_: ".myWrapper";
        padding-bottom: 60px;
        @include tabletP {
            padding-bottom: 30px;
        }
    }//.myWrapper

    .myBlockPresent {
        $_: ".myBlockPresent";
        background-color: #566674;
        margin-bottom: 95px;
        padding: 20px 0 20px;
        @include tabletP {
            margin-bottom: 27px;
            padding-bottom: 30px;
        }
        &__container {
            @include tabletP {
                max-width: 490px;
                padding-left: 20px;
                padding-right: 20px;
            }
        }
        &__inner {
            position: relative;
            padding: 28px 41px 30px;
            @include tabletP {
                padding: 18px 30px 30px;
            }
            &::before,
            &::after {
                content: "";
                position: absolute;
                pointer-events: none;
                width: calc(100% - 40px);
                height: calc(100% - 40px);
                @include tabletP {
                    width: calc(100% - 30px);
                    height: calc(100% - 30px);
                }
            }
            &::before {
                top: 0;
                left: 0;
                border-top: 1px solid #E5C50C;
                border-left: 1px solid #E5C50C;
            }
            &::after {
                bottom: 0;
                right: 0;
                border-bottom: 1px solid #E5C50C;
                border-right: 1px solid #E5C50C;
            }
        }
        &__title {
            color: #e5c50c;
            @include fz(24);
            font-family: $baseFontFamilyBold;
            font-weight: bold;
            letter-spacing: 0.04em;
            line-height: 1.4;
            margin-bottom: 8px;
            @include tabletP {
                text-align: center;
                margin-bottom: 4px;
            }
        }
        &__txt {
            color: #fff;
            @include fz(20);
            font-family: $baseFontFamilyBold;
            font-weight: bold;
            letter-spacing: 0.04em;
            line-height: (30 / 20);
            text-indent: -1em;
            padding-left: 1em;
            @include tabletP {
                @include fz(16);
                line-height: (24 / 16);
            }
        }
        &__image {
            @include tabletPmin {
                position: absolute;
                bottom: -58px;
                right: 32px;
                width: 420px;
                @include max(1024) {
                    bottom: 2px;
                    right: -20px;
                    width: 240px;
                }
            }
            @include tabletP {
                text-align: center;
                margin: -6px -30px -58px -38px;
            }
        }
    }//.myBlockPresent

    .myBlockSection {
        $_: ".myBlockSection";
        &:not(:last-child) {
            margin-bottom: 92px;
            @include tabletP {
                margin-bottom: 30px;
            }
        }
        &__container {
            max-width: 876px;
            @include tabletP {
                padding-left: 20px;
                padding-right: 20px;
            }
        }
        &__title {
            color: #000;
            @include fz(24);
            font-family: $baseFontFamilyBold;
            font-weight: bold;
            letter-spacing: 0.04em;
            line-height: 1.4;
            text-align: center;
            margin-bottom: 25px;
            @include tabletP {
                @include fz(20);
                margin-bottom: 19px;
            }
            &::after {
                content: "";
                background-color: #566674;
                display: block;
                width: 100px;
                height: 6px;
                margin: 6px auto 0;
                @include tabletP {
                    margin-top: 1px;
                }
            }
        }
        &__lead {
            color: #000;
            @include fz(20);
            letter-spacing: 0.04em;
            line-height: (30 / 20);
            margin-bottom: 43px;
            @include tabletP {
                @include fz(14);
                line-height: (20 / 14);
                margin-bottom: 20px;
            }
        }
        &__image {
            text-align: center;
        }
    }//.myBlockSection

    .myBlockApply {
        $_: ".myBlockApply";
        background-color: #566674;
        text-align: center;
        margin-top: 100px;
        padding: 55px 0 60px;
        @include tabletP {
            margin-top: 30px;
            padding: 27px 0 30px;
        }
        &__container {
            @include tabletP {
                padding-left: 20px;
                padding-right: 20px;
            }
        }
        &__txt {
            color: #fff;
            @include fz(20);
            font-family: $baseFontFamilyBold;
            font-weight: bold;
            letter-spacing: 0.04em;
            line-height: (30 / 20);
            margin-bottom: 15px;
            @include tabletP {
                @include fz(14);
                line-height: (22 / 14);
                margin-bottom: 16px;
            }
        }
        &__link {
            border: 1px solid #fff;
            display: block;
            position: relative;
            color: #fff;
            @include fz(18);
            font-family: $baseFontFamilyBold;
            font-weight: bold;
            letter-spacing: 0.04em;
            line-height: 1.4;
            text-align: center;
            text-decoration: none;
            max-width: 320px;
            width: 100%;
            margin: 0 auto;
            padding: 17px 20px 16px;
            @include tabletP {
                max-width: 335px;
            }
            &::after {
                content: "";
                border-top: 1px solid #fff;
                border-right: 1px solid #fff;
                position: absolute;
                top: 0;
                bottom: 0;
                right: 17px;
                width: 10px;
                height: 10px;
                margin: auto;
                transform: rotate(45deg);
            }
        }
    }//.myBlockApply
}