@charset "utf-8";

/*
top
*/
$id:top;
$cmnPath:'../img/common/';
$imgPath:'../img/#{$id}/';
body#page_#{$id} {
    background-color: #000;

    $_h: get_vw(1160, 1920);
    $_hSp:'450px';

    .myWrapper {
        $_: ".myWrapper";
        position: relative;
    }//.myWrapper

    .myContents {
        $_: ".myContents";
        background: #fff;
        position: relative;
        padding-top: 100px;
        z-index: 2;
        @include tabletP {
            padding-top: 30px;
        }
    }//.myContents

    .myBlockMv {
        $_: ".myBlockMv";
        position: relative;
        max-height: calc(100vh - #{$headerHeight});
        height: #{$_h};
        z-index: 1;
        @include tabletP {
            max-height: calc(100vh - #{$headerHeightSp});
            height: #{$_hSp};
        }
        &-slider{
            background-color: #fff;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 1;

            &__inner {
                position: relative;
                height: 100%;
            }
            &__slide{
                background-repeat: no-repeat;
                background-position: center;
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                &:hover {
                    opacity: 1;
                }
                &.-slide01 {
                    background-color: #F2ECE5;
                    background-image: url(#{$imgPath}img_mv01.png);
                    background-size: auto 100%;
                    @include retina {
                        background-image: url(#{$imgPath}img_mv01@2x.png);
                    }
                    @include tabletP {
                        background-image: url(#{$imgPath}img_mv01_sp.png);
                        @include retina {
                            background-image: url(#{$imgPath}img_mv01_sp@2x.png);
                        }
                    }
                }
                &.-slide02 {
                    background-image: url(#{$imgPath}img_mv02.png), url(#{$imgPath}bg_mv02.jpg);
                    background-size: auto 100%, cover;
                    @include retina {
                        background-image: url(#{$imgPath}img_mv02@2x.png),url(#{$imgPath}bg_mv02@2x.jpg);
                    }
                    @include tabletP {
                        background-image: url(#{$imgPath}img_mv02_sp.png),url(#{$imgPath}bg_mv02_sp.jpg);
                        @include retina {
                            background-image: url(#{$imgPath}img_mv02_sp@2x.png),url(#{$imgPath}bg_mv02_sp@2x.jpg);
                        }
                    }
                }
                &.-slide03 {
                    background-image: url(#{$imgPath}bg_mv03.jpg);
                    background-size: cover;
                    @include retina {
                        background-image: url(#{$imgPath}bg_mv03@2x.jpg);
                    }
                    @include tabletP {
                        background-image: url(#{$imgPath}bg_mv03_sp.jpg);
                        @include retina {
                            background-image: url(#{$imgPath}bg_mv03_sp@2x.jpg);
                        }
                    }
                }
            }

            // slick
            .slick-list {
                height: 100%;
            }
            .slick-track {
                position: relative;
                display: flex;
                height: 100%;
            }
            .slick-slide {
                position: relative;
                height: auto;
                & > div {
                    height: 100%;
                }
            }
        }
    }//.myBlockMv

    @keyframes fadeIn {
        0% {
            opacity: 0;
            transform: translateY(60px);
        }

        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }

    .myBlockInfo {
        $_: ".myBlockInfo";
        margin-bottom: 100px;
        @include tabletP {
            margin-bottom: 30px;
        }
        &__inner {
            background-color: #534741;
            color: #fff;
            padding: 57px 72px 103px;
            @include tabletP {
                margin: 0 -10px;
                padding: 21px 20px 24px;
            }
        }
        &__ttl {
            font-family: $fontGillSans;
            font-weight: 400;
            @include fz(19);
            line-height: 1.4;
            margin-bottom: 35px;
            @include tabletP {
                @include fz(14);
                margin-bottom: 20px;
            }
        }
        &__list {}
        &__item {
            @include fz(18);
            line-height: (15.29 / 12);
            @include tabletP {
                @include fz(12);
            }
            &:not(:last-child) {
                margin-bottom: 30px;
                @include tabletP {
                    margin-bottom: 9px;
                }
            }
            &Inner {
                display: flex;
                align-items: baseline;
                color: inherit;
                text-decoration: none;
                width: 100%;
                @include tabletP {
                    display: block;
                }
            }
        }
        &__date {
            display: block;
            flex-shrink: 0;
            font-family: $fontGillSans;
            font-weight: 400;
            letter-spacing: 0.1em;
            margin-right: 20px;
            @include tabletP {
                display: inline;
                margin-right: 0.5em;
            }
        }
        &__txt {
            display: block;
            font-family: $fontShueiNGoGinB;
            letter-spacing: -0.02em;
            @include tabletP {
                display: inline;
            }
        }
    }//.myBlockInfo

    .myBlockNav {
        $_: ".myBlockNav";
        margin-bottom: 224px;
        @include tabletP {
            margin-bottom: 81px;
        }
        &__list {
            display: flex;
            flex-wrap: wrap;
            @include tabletP {
                margin: 0 -#{$containerPadding}px;
            }
        }
        &__item {
            position: relative;
            width: calc(33.3333% + 2px);
            margin: -1px;
            @include tabletP {
                width: calc(100% + 2px);
            }
            &Inner {
                display: block;
                &:hover {
                    opacity: 1;
                    #{$_}__bg {
                        opacity: .2;
                    }
                }
            }
        }
        &__bg {
            transition: .2s;
            img {
                width: 100%;
            }
        }
        &__txt {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            img {
                width: 100%;
            }
        }
    }//.myBlockNav

    .myBlockVoice {
        $_: ".myBlockVoice";
        background: url(#{$imgPath}bg_voice.jpg) no-repeat center;
        background-size: cover;
        padding: 57px 0 60px;
        @include tabletL {
            background-image: url(#{$imgPath}bg_voice_sp.jpg);
            padding: 48px 0 50px;
        }
        .container {
            @include tabletP {
                max-width: 450px;
                margin: 0 auto;
            }
        }
        &__head {
            margin-bottom: 88px;
            @include tabletP {
                margin-bottom: 42px;
            }
        }
        &__txt {
            background-color: #6D9684;
            border-radius: 12px;
            position: relative;
            color: #fff;
            @include fz(25);
            font-family: $baseFontFamilyExtraBold;
            font-weight: 900;
            letter-spacing: 0.1em;
            line-height: 1.4;
            text-align: center;
            padding: 30px 10px 30px;
            @include tabletP {
                @include fz(16);
                letter-spacing: 0.1em;
                line-height: (29.6 / 16);
                padding: 16px 10px 16px;
                margin: 0 -28px;
            }
        }
        &__list {
            position: relative;
            overflow: hidden;
            @include tabletP {
                margin: 0 #{-$containerPadding}px;
            }
            &Inner {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                position: relative;
                margin-bottom: -50px;
                z-index: 2;
                @include tabletL {
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: -20px;
                }
            }
        }
        &-item {
            background-color: #fff;
            display: inline-block;
            border-radius: 29.36px;
            position: relative;
            color: #212121;
            @include fz(18);
            font-family: $fontA1Gothic;
            letter-spacing: 0.14em;
            line-height: (23 / 18);
            max-width: 348px;
            margin-bottom: 50px;
            padding: 35px 47px 41px;
            cursor: pointer;
            @include tabletLmin {
                width: percentage(380px/1200px);
            }
            @include tabletP {
                @include fz(15);
                letter-spacing: 0.14em;
                line-height: (23 / 15);
                max-width: 294px;
                margin-bottom: 20px;
                padding: 47px 44px 30px;
            }
            &:hover {
                @include tabletLmin {
                    // opacity: .8;
                    // transition: .2s;
                }
            }
            &.-item01 {
                @include tabletL {
                    margin-left: -56px;
                }
            }
            &.-item02 {
                @include tabletL {
                    margin-right: -56px;
                }
            }
            &.-item03 {
                @include tabletL {
                    margin-left: -56px;
                }
            }
            &.-item04 {
                @include tabletL {
                    margin-right: -56px;
                }
            }
            &.-item05 {
                @include tabletL {
                    margin-left: -56px;
                }
            }
            &.-item06 {
                @include tabletL {
                    margin-right: -56px;
                }
            }
            &__title {
                margin-bottom: 36px;
                @include tabletP {
                    margin-bottom: 25px;
                }
            }
        }
    }//.myBlockVoice

    .myBlockVoiceModal {
        $_: ".myBlockVoiceModal";
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 100;
        transition: opacity .3s, visibility 0s;
        &[aria-hidden = "true"] {
            opacity: 0;
            pointer-events: none;
            transition: opacity .3s, visibility 0s .3s;
            visibility: hidden;
        }
        &__overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            cursor: pointer;
        }
        &__box {
            background-color: #6d9886;
            border: 8px solid #fff;
            border-radius: 25.16px;
            position: relative;
            max-width: 730px;
            width: 100%;
            max-height: calc(100vh - 40px);
            padding: 27px 27px 27px 33px;
            overflow-y: auto;
            @include tabletP {
                border-width: 3px;
                max-width: 400px;
                width: calc(100% - 20px);
                padding: 15px 8px 15px;
            }
        }
        &__item {
            position: relative;
            @include tabletPmin {
                display: flex;
                align-items: center;
            }
            &:not(:last-child) {
                margin-bottom: 40px;
                @include tabletP {
                    margin-bottom: 35px;
                }
            }
        }
        &__txt {
            border-radius: 9.44px;
            background-color: #fff;
            position: relative;
            color: #000;
            @include fz(18);
            font-family: $fontA1GothicMedium;
            font-weight: 500;
            line-height: 1.7;
            padding: 10px 16px 12px;
            @include tabletPmin {
                flex-basis: 100%;
            }
            @include tabletP {
                @include fz(15);
                letter-spacing: -0.1em;
                line-height: 1.4;
                padding: 7px 36px 6px 12px;
            }
            &::after {
                content: "";
                border-top: 15px solid #fff;
                border-right: 17px solid transparent;
                display: block;
                position: absolute;
                top: calc(100% - 1px);
                left: 25px;
                width: 0;
                height: 0;
                transform-origin: right 0;
                transform: skew(-22deg);
                @include tabletP {
                    border-top: 12px solid #fff;
                    border-right: 13px solid transparent;
                    left: 19px;
                }
            }
        }
        &__link {
            color: #ff0;
            @include fz(18);
            font-family: $baseFontFamilyBold;
            font-weight: 700;
            line-height: (23.5 / 18);
            text-decoration: none;
            text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.75);
            padding: 14px 19px 11px 17px;
            margin-left: 17px;
            @include tabletPmin {
                border: 1px solid #ff0;
                display: flex;
                align-items: center;
                flex-shrink: 0;
            }
            @include tabletP {
                position: absolute;
                top: 0;
                left: 0;
                font-size: 0;
                width: 100%;
                height: 100%;
                padding: 0;
                margin: 0;
            }
            &::before {
                content: "";
                background: url(#{$imgPath}arrow_voicemodal.svg) no-repeat center;
                display: block;
                position: relative;
                left: -8px;
                width: 36px;
                height: 44px;
                @include tabletP {
                    background-image: url(#{$imgPath}arrow_voicemodal_sp.svg);
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: auto;
                    right: 6px;
                    width: 28px;
                    height: 34px;
                    margin: auto;
                }
            }
        }
    }//.myBlockVoiceModal
}
