@charset "utf-8";

/*
archives
*/
$id:archives;
$cmnPath:'../img/common/';
$imgPath:'../img/#{$id}/';
body#page_#{$id} {

    $_root:&;
    $pageColor: #0A1D37;

    .myWrapper{
        padding-top: 133px;
        padding-bottom: (103-35)+px;
        overflow: hidden;
        @include tabletP{
            padding-top: 44px;
            padding-bottom: 90px;
        }

    }

    .myBlockContents {
        position: relative;
    }

    .myBlockSection {
        opacity: 1;
        transition: opacity .2s ease;
        &:not([aria-hidden = "false"]) {
            position: absolute;
            top: 0;
            opacity: 0;
            width: 100%;
            max-height: 100vh;
            pointer-events: none;
            z-index: -1;
        }
    }


    .myCaption{
        position: absolute;
        bottom: 10px;
        right: 10px;
        @include fz(14);
        color:#fff;
        font-weight: bold;
        letter-spacing: 0;
        text-shadow:
        rgba(#000,0.7) 1px 1px 1px,
        rgba(#000,0.7) 0 0 1px
            ;
        font-family: $fontMincho;
    }


    .myBlock01{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 50px;
        position: relative;

        $_:'.myBlock01';

        &.-decoRB{

            #{$_}__box.-detail{
                padding-bottom: 170px;
                position: relative;
                @include tabletP{
                    padding-bottom: 90px;
                }
                &::before,
                &::after{
                    content:"";
                    position: absolute;
                    background-color: #0a1d37;

                }
                &::before{
                    bottom: 0;
                    right: 40px;
                    width: 1px;
                    height: 170px;
                    @include tabletP{
                        height: 90px;
                    }
                }
                &::after{
                    bottom: 40px;
                    right: 0;
                    width: 334px;
                    height: 1px;
                    @include tabletP{
                        width: 100%;
                    }
                }
            }

        }

        &.-decoRT{
            margin-top: 96px;

            #{$_}__box.-detail{
                padding-top: 30px;
                position: relative;
                &::before,
                &::after{
                    content:"";
                    position: absolute;
                    background-color: #0a1d37;

                }
                &::before{
                    top: -40px;
                    right: 40px;
                    width: 1px;
                    height: 170px;
                    @include tabletP{
                        top: 0;
                        height: 90px;
                    }
                }
                &::after{
                    top: 0;
                    right: 0;
                    width: 334px;
                    height: 1px;
                    @include tabletP{
                        top: 40px;
                        width: 100%;
                    }
                }
            }

        }

        &.-decoLB{

            #{$_}__box.-detail{
                padding-bottom: 170px;
                position: relative;
                &::before,
                &::after{
                    content:"";
                    position: absolute;
                    background-color: #0a1d37;

                }
                &::before{
                    bottom: 0;
                    left: 40px;
                    width: 1px;
                    height: 170px;
                    @include tabletP{
                        height: 90px;
                    }
                }
                &::after{
                    bottom: 40px;
                    left: 0;
                    width: 334px;
                    height: 1px;
                    @include tabletP{
                        width: 100%;
                    }
                }
            }

        }

        &.-type02{
            #{$_}__box.-left.-image{
                @include tabletPmin{
                    padding-left: 70px;
                    padding-right: 24px;
                }
            }


            #{$_}__box.-right.-detail{
                #{$_}__txt{
                    @include tabletPmin{
                        padding-left: 56px;
                    }
                }
            }
        }

        &.-type03{
            @include tabletPmin{
                justify-content: flex-end;
                margin-top: -150px;
            }
            #{$_}__box.-right.-image{
                @include tabletPmin{
                    padding-right: 70px;
                    padding-left: 24px;
                }
            }

        }

        &.-type04{
            @include tabletPmin{
                margin-top: 220px;
            }

            #{$_}__box.-right.-detail{
                #{$_}__txt{
                    @include tabletPmin{
                        padding-left: 200px;
                    }
                }
            }
        }

        &.-block03_02{

            #{$_}__box.-left.-detail{
                #{$_}__txt{
                    @include tabletPmin{
                        margin-top: 160px;
                        padding-left: 110px;
                    }
                }
            }
        }


        &.-block05_01{

            #{$_}__box.-left.-detail{
                @include tabletPmin{
                    padding-top: 80px;
                }
            }
            #{$_}__box.-right.-image{
                @include tabletPmin{
                    text-align: left;
                }
                #{$_}__inner{
                    display: inline-block;
                    position: relative;
                }
            }
        }


        &:first-child{
            margin-top: 186px;
            @include tabletP{
                margin-top: 80px;
            }
        }

        &__box{
            @include tabletPmin{
                width: 50%;
            }
            @include tabletP{
                width: 100%;
                margin-bottom: 20px;
            }

            &.-image{
                position: relative;
                text-align: center;

                @include tabletP{
                    order:2;
                }
            }

            &.-detail{
                @include tabletP{
                    order:1;
                }

                &.-right{

                    #{$_}__txt{
                        @include tabletPmin{
                            padding-left: 133px;
                        }
                    }

                }

                &.-left{
                    #{$_}__txt{
                        @include tabletPmin{
                            padding-left: 70px;
                        }
                    }

                }
            }

            &.-right{
            }

            &.-left{
            }
        }

        &__name{
            margin-bottom: 91px;
            text-align: right;
            @include tabletP{
                margin-bottom: 50px;
            }
        }

        &__txt{
            margin-top: 45px;
            letter-spacing: 0.06em;
            font-family: $fontMincho;
            @include fz(13);
            line-height: 3;
            font-weight: bold;
            @include tabletP{
                @include fz(14);
            }
        }

        &__inner{
            position: relative;
            @include tabletP{
                display: inline-block;
            }
        }

        &__image{
            position: relative;
            text-align: center;
            &.-type01{
                @include tabletPmin{
                    max-width: 400px;
                    margin-top: 101px;
                    margin-bottom: 220px;
                }
            }
            &.-type02{
                @include tabletPmin{
                    max-width: 400px;
                    margin: -249px 0 240px 130px;
                }
            }
        }
    }//.myBlock01

    .myBlock02{
        position: relative;
        margin-bottom: 50px;
        text-align: center;

        $_:'.myBlock02';


        &__inner{
            @include tabletP{
                position: relative;
                display: inline-block;
            }
        }

        &.-inline
        {

            #{$_}__inner{
                position: relative;
                display: inline-block;

            }

        }


        &.-left{
            @include tabletPmin{
                text-align: left;
            }

            #{$_}__inner{
                position: relative;
                display: inline-block;

            }
        }


        &.-right{
            @include tabletPmin{
                text-align: right;
            }

            #{$_}__inner{
                position: relative;
                display: inline-block;

            }
        }
        &.-block03_03{

        }

        &.-block03_04{
            @include tabletPmin{
                max-width: 600px;
                margin-left: 100px;
            }


        }

        &.-block03_05{
        }

        &.-block03_06{
            @include tabletPmin{
                padding-left: 200px;
            }

        }

        &.-block03_07{
            @include tabletPmin{
                max-width: 550px;
            }

        }

        &:last-child{
            margin-bottom: 250px;
            @include tabletP{
                margin-bottom: 80px;
            }

        }
    }//.myBlock02


    .myBlock03{
        max-width: 1021px;
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 50px;
        @include tabletPmin{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        $_:'.myBlock03';

        &.-decoRB{
            padding-bottom: 160px;
            position: relative;
            @include tabletP{
                padding-bottom: 90px;
            }
            &::before,
            &::after{
                content:"";
                position: absolute;
                background-color: #0a1d37;

            }
            &::before{
                bottom: 0;
                right:20px;
                width: 1px;
                height: 117px;
                @include tabletP{
                    height: 90px;
                }
            }
            &::after{
                bottom: 20px;
                right: 0;
                width: 230px;
                height: 1px;
                @include tabletP{
                    width: 100%;
                }
            }
        }

        &.-decoLB{
            padding-bottom: 160px;
            position: relative;
            @include tabletP{
                padding-bottom: 90px;
            }
            &::before,
            &::after{
                content:"";
                position: absolute;
                background-color: #0a1d37;

            }
            &::before{
                bottom: 0;
                left:20px;
                width: 1px;
                height: 117px;
                @include tabletP{
                    height: 90px;
                }
            }
            &::after{
                bottom: 20px;
                left: 0;
                width: 230px;
                height: 1px;
                @include tabletP{
                    width: 100%;
                }
            }
        }

        &__item{

            @include tabletPmin{
                width: 50%;
            }

            @include tabletP{
                margin-bottom: 20px;
                text-align: center;
            }

            &.-left{

                @include tabletPmin{
                    text-align: left;
                }

            }

            &.-right{
                @include tabletPmin{
                    text-align: right;
                }
            }

        }

        &__inner{
            position: relative;
            display: inline-block;
        }


        &.-block04_02{

            #{$_}__item{
                &.-left{
                    @include tabletPmin{
                        padding-top: 130px;
                    }

                }
                &.-right{

                }
            }

        }

        &.-block04_04{

            #{$_}__item{
                &.-left{

                }
                &.-right{
                    @include tabletPmin{
                        padding-top: 290px;
                    }

                }
            }

        }


        &.-block05_03{
            max-width: 852px;

            #{$_}__item{
                &.-left{

                }
                &.-right{
                    @include tabletPmin{
                        padding-top: 64px;
                    }

                }
            }

        }

        &.-block06_04{
            max-width: none;

            #{$_}__item{
                &.-left{

                }
                &.-right{
                    @include tabletPmin{
                        padding-top: 348px;
                    }

                }
            }

        }



    }//


    .myDeco{
        position: relative;
        margin-bottom: 70px;
        width: 330px;
        height: 120px;
        position: relative;
        @include tabletP{
            width: 100%;
            height: 90px;
        }
        &::before,
        &::after{
            content:"";
            position: absolute;
            background-color: #0a1d37;

        }
        &::before{
            top: 0;
            left:90px;
            width: 1px;
            height: 120px;
            @include tabletP{
                height: 90px;
                left: 20px;
            }
        }
        &::after{
            top: 20px;
            left: 70px;
            width: 230px;
            height: 1px;
            @include tabletP{
                width: 100%;
                left: 0;
            }
        }
    }//.myDeco


    .myBlock04{
        margin-bottom: 50px;

        &__pc{
            @include tabletP{
                display: none;
            }
        }

        &__sp{
            @include tabletPmin{
                display: none;
            }
        }


        &__item{
            margin-bottom: 20px;
            text-align: center;

        }

        &__inner{
            position: relative;
            display: inline-block;
        }

    }//.myBlock04



    .myTxt{
        margin-bottom: 95px;
        text-align: center;
        @include tabletP{
            margin-bottom: 60px;
        }

        &__inner{
            position: relative;
            padding-top: 84px;
            padding-left: 112px;
            padding-right: 112px;
            display: inline-block;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 1.4;
            font-family: $fontMincho;
            @include fz(19);


            @include tabletP{
                padding-right: 20px;
                padding-left: 20px;
                padding-top: 40px;
            }

            @include sphoneP{
                @include fz(15);
            }

            @include sphoneSE{
                @include fz(13);
            }


            &::before,
            &::after
            {
                position: absolute;
                background-color: #0a1d37;
            }

            &::before{
                content:"";
                left: 20px;
                top: 0;
                width: 1px;
                height: 100px;
                @include tabletPmin{

                }
                @include tabletP{
                    height: calc(1em + 45px);
                    left: 5px;
                }
            }

            &::after{
                content:"";
                top: 20px;
                left: 0;
                width: 200px;
                height: 1px;
                @include tabletPmin{
                }
            }
        }

        br{
            @include tabletPmin{
                display: none;
            }
        }

        &__txt{
            display: inline-block;
            @include tabletP{
            }
        }
    }//.myTxt






}
