@charset "utf-8";
/*
#overview
modBlock
*/

/*
#styleguide

modBlockWrapper

下層の白背景、ページタイトル用のrelative付与
```
<div class="modBlockWrapper"></div>
```
*/

.modBlockWrapper{
    background-color: #fff;
    position: relative;

}//.modBlockWrapper


/*
#styleguide

modBlock404

404用
```
404.php参照
```
*/

.modBlock404{


    &-header{
        background-color: #000;
        margin-top: -$headerHeight;
        padding: calc(54px + 100px) 0 100px;
        text-align: center;

        @include tabletP {
            margin-top: -$headerHeightSp;
            padding: calc(20px + 10vw) 0 10vw;
        }

        @include sphoneP{
            padding: calc(20px + 16vw) 0 16vw;
        }

        &__title{
            font-weight: bold;
            font-family: $fontMontserrat;
            @include fz(30);
            color:#fff;
            @include sphoneP{
                @include fz(18);
            }
        }
    }

    &__inner{
        padding: 100px 0;
        @include sphoneP{
            padding: 50px 0;
        }

    }

    &__link{
        display: block;
        max-width: 300px;
        padding: 10px;
        margin: 20px 0 0;
        border:solid 1px #000;
        transition:color,background-color 0.3s ease;
        text-decoration: none;
        text-align: center;

        &:hover{
            opacity: 1;
            background-color: #000;
            color:#fff;
        }
    }

}//.modBlock404

/*
#styleguide

modBlockSupportDetailOnline

```
<div class="modBlockSupportDetailOnline">
    <h2 class="modBlockSupportDetailOnline__title">オンラインでの面談も可能</h2>
    <figure class="modBlockSupportDetailOnline__image">
        <picture>
            <source srcset="/assets/img/common/img_support_detail_online_sp.png 1x,/assets/img/common/img_support_detail_online_sp@2x.png 2x" media="(max-width: 768px)">
            <img src="/assets/img/common/img_support_detail_online.png" srcset="/assets/img/common/img_support_detail_online@2x.png 2x" alt="ご自宅にいながら 専門家にご相談が可能 パソコン・タブレットで資料などをご説明 ID登録やアプリ不要">
        </picture>
    </figure>
    <a href="" class="modBlockSupportDetailOnline__link">お申し込みはこちら</a>
</div>
```
*/

.modBlockSupportDetailOnline{
    $_: &;
    background-color: #E6E9EF;
    padding: 56px 0 60px;
    @include tabletP {
        padding: 28px 0 30px;
    }
    &__container {
        @include tabletP {
            padding-left: 20px;
            padding-right: 20px;
        }
    }
    &__title {
        color: #354859;
        @include fz(24);
        font-family: $baseFontFamilyBold;
        font-weight: bold;
        letter-spacing: 0.04em;
        line-height: 1.4;
        text-align: center;
        margin-bottom: 36px;
        @include tabletP {
            @include fz(20);
            margin-bottom: 17px;
        }
    }
    &__image {
        text-align: center;
        margin-bottom: 60px;
        @include tabletP {
            margin: 0 -10px 30px;
        }
    }
    &__link {
        background-color: #fff;
        border: 1px solid #000;
        display: block;
        position: relative;
        color: #000;
        @include fz(18);
        font-family: $baseFontFamilyBold;
        font-weight: bold;
        letter-spacing: 0.04em;
        line-height: 1.4;
        text-align: center;
        text-decoration: none;
        max-width: 320px;
        width: 100%;
        margin: 0 auto;
        padding: 17px 20px 16px;
        @include tabletP {
            max-width: 335px;
        }
        &::after {
            content: "";
            border-top: 1px solid #000;
            border-right: 1px solid #000;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 17px;
            width: 10px;
            height: 10px;
            margin: auto;
            transform: rotate(45deg);
        }
    }
}//.modBlockSupportDetailOnline
