@charset "utf-8";

/*
loan_detail
*/
$id:loan_detail;
$cmnPath:'../img/common/';
$imgPath:'../img/#{$id}/';
body#page_#{$id} {
    $_root: &;
    .gHeader {
        background-color: #7E7E7E;
    }

    .myWrapper {
        $_: ".myWrapper";
        padding-bottom: 60px;
        @include tabletP {
            padding-bottom: 30px;
        }
    }//.myWrapper

    .myBlockLoan {
        $_: ".myBlockLoan";
        margin: -5px 0 90px;
        @include tabletP {
            margin: -2px 0 25px;
        }
        &__container {
            @include tabletP {
                padding-left: 20px;
                padding-right: 20px;
            }
        }
        &__lead {
            color: #000;
            @include fz(20);
            letter-spacing: 0.04em;
            line-height: (30 / 20);
            text-align: center;
            margin-bottom: 54px;
            @include tabletP {
                @include fz(14);
                line-height: (20 / 14);
                text-align: left;
                margin-bottom: 25px;
            }
        }
        &__list {
            margin-bottom: 56px;
            @include tabletPmin {
                background: url(#{$imgPath}bg_loan.jpg) no-repeat 0 0;
                background-size: 620px;
                padding-top: 92px;
                @include retina {
                    background-image: url(#{$imgPath}bg_loan@2x.jpg);
                }
            }
            @include tabletP {
                background: url(#{$imgPath}bg_loan_sp.jpg) no-repeat center;
                background-size: cover;
                margin: 0 -20px 28px;
                padding: 30px 20px 30px;
                @include retina {
                    background-image: url(#{$imgPath}bg_loan_sp@2x.jpg);
                }
            }
            &-inner {
                background-color: #fff;
                position: relative;
                max-width: 579px;
                width: 100%;
                margin: 0 0 0 auto;
                padding: 79px 79px 74px;
                @include tabletP {
                    background-color: rgba(#fff, .85);
                    max-width: 375px;
                    padding: 25px 26px 32px;
                    margin: 0 auto;
                }
                &::before {
                    content: "";
                    border: 1px solid #566674;
                    position: absolute;
                    top: 10px;
                    bottom: 10px;
                    left: 10px;
                    right: 10px;
                    pointer-events: none;
                    @include tabletP {
                        top: 6px;
                        bottom: 6px;
                        left: 6px;
                        right: 6px;
                    }
                }
            }
        }
        &__item {
            &:not(:last-child) {
                margin-bottom: 44px;
                @include tabletP {
                    margin-bottom: 25px;
                }
            }
            &-title {
                background-color: #e6e9ef;
                border: 1px solid #566674;
                color: #566674;
                @include fz(20);
                font-family: $baseFontFamilyBold;
                font-weight: bold;
                letter-spacing: 0.04em;
                line-height: 1.4;
                text-align: center;
                max-width: 220px;
                margin-bottom: 15px;
                padding: 11px 20px 10px;
                @include tabletP {
                    @include fz(18);
                    max-width: 160px;
                    margin: 0 auto 18px;
                    padding: 7px 10px 7px;
                }
            }
            &-txt {
                color: #000;
                @include fz(20);
                line-height: (30 / 20);
                @include tabletP {
                    @include fz(14);
                    line-height: (20 / 14);
                }
            }
        }
        &__txt {
            color: #000;
            @include fz(24);
            font-family: $baseFontFamilyBold;
            font-weight: bold;
            letter-spacing: 0.04em;
            line-height: (40 / 24);
            text-align: center;
            @include tabletP {
                @include fz(18);
                line-height: (24 / 18);
            }
        }
        &__image {
            text-align: center;
            margin-bottom: 56px;
            @include tabletP {
                margin-bottom: 28px;
            }            
        }
    }//.myBlockLoan
}