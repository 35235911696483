@charset "utf-8";

/*
reason
*/
$id:reason;
$cmnPath:'../img/common/';
$imgPath:'../img/#{$id}/';
$_pageColor: #073A59;
body#page_#{$id} {

    $_root:&;

    .gHeader {
        background-color: rgba(#000, .5);
    }

    .myWrapper{
        padding-top: 91px;
        padding-bottom: (180-35)+px;
        overflow: hidden;
        @include tabletP{
            padding-top: 25px;
            padding-bottom: 90px;
        }

    }

    .myListMenu{
        $_:'.myListMenu';


        &__item{
            &:not(:last-child){
                margin-bottom: 50px;
                @include tabletP{
                    margin-bottom: 30px;
                }
            }
        }

        &__inner{
            display: block;
            position: relative;
            transition:color,background-color 0.3s ease;
            background-color: #3C90A4;
//            box-shadow: nth($mainColor,1) 0 0 0 1px;
            position: relative;
            padding: 30px 100px 19px;
            text-decoration: none;
            color:#fff;
            text-align: center;

            @include tabletP{
                padding: 20px 40px 10px 30px;
                margin-right: -#{$containerPadding}px;
                margin-left: -#{$containerPadding}px;
            }


            &::after{
                content:"";
                position: absolute;
                top: 0;
                right: 20px;
                bottom: 0;
                margin: auto 0;
                background-image: url(../img/reason/arrow.svg);
                background-position: 0 0;
                background-repeat: no-repeat;
                background-size: 55px 55px;
                width: 55px;
                height: 55px;
                @include tabletP{
                    background-size: 25px 25px;
                    width: 25px;
                    height: 25px;
                    right: 10px;
                }
            }
        }

        a#{$_}__inner{
            &:hover{
                opacity: 1;
                background-color: #4D4D4D;
            }
        }

        &__num{
            display: block;
            width: 80px;
            height: 80px;
            position: absolute;
            top: 0;
            left: 0;
            padding-left: 13px;
            font-family: $fontEB;
            @include fz(36);
            font-weight: bold;
            line-height: 1.4;
            text-align: left;
            @include tabletP{
                width: 70px;
                height: 70px;
                @include fz(30);
            }

            &::before{
                content:"";
                position: absolute;
                bottom: 0;
                left: 0;
                height: 0;
                width: 0px;
                margin: auto;
                border: 40px solid transparent;
                border-left-color: $_pageColor;
                border-top-color: $_pageColor;
                @include tabletP{
                    border-width: 35px;
                }
            }

            span{
                display: block;
                position: relative;
            }

        }

        &__title{
            display: block;
            margin-bottom: 9px;
            @include fz(32);
            font-weight: bold;
            font-family: $baseFontFamilyBold;
            text-shadow: rgba(0,0,0,0.75) 1px 1px 5px;
            letter-spacing: 0.02em;
            line-height: 1.4;
            @include tabletP{
                @include fz(20);
                padding: 0 1em;
            }
        }

        &__txt{
            display: block;
            padding: 8px;
            background-color: rgba(#000000,0.5);
            @include fz(22);
            font-weight: 500;
            text-shadow: rgba(0,0,0,0.75) 1px 1px 5px;
            letter-spacing: 0.02em;
            line-height: 1.4;
            @include tabletP{
                @include fz(18);
            }

        }

        :-ms-lang(x)::-ms-backdrop, #{$_}__txt{
            padding-top: 20px;
            padding-bottom: 0;
        }

    }

    .myNav{
        position: fixed;
        z-index: 4;

        @include print{
            display: none;
        }

        @include tabletPmin{
            top: 0;
            bottom: 0;
            right: 0;
            width: 170px;
        }

        @include max(1319){
            width: 50px;
        }


        $_:'.myNav';

        &[data-scroll="true"]{
            @include tabletP{
                visibility: hidden;
                opacity: 0;
            }
        }

        &[data-scroll="false"]{
            @include tabletP{
                opacity: 1;
                visibility: visible;
            }
        }


        @include tabletP{
            width: auto;
            bottom: calc(23px + 8px + 2.8em + 20px);
            left: 8px;
            right: 8px;
            transition:all 0.3s ease;
        }

        &__body{
            @include tabletPmin{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                height: 100%;
            }
        }

        &__inner{
            box-shadow: rgba(#000,0.75) 1px 1px 3px;
            @include tabletPmin{
                width: 100%;
            }

            @include tabletP{
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
            }

        }

        &__item{
            display: block;
            position: relative;
            background-color: #3C90A4;
            color:#fff;
            line-height: 1.3;
            text-decoration: none;
            text-align: center;
            @include tabletPmin{
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                border-top: solid 1px $_pageColor;
            }
            @include tabletP{
                flex:1;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: flex-end;
                padding: 10px 4px 6px 25px;
                box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.75);
                line-height: 1;
            }

            @include sphoneSE{
                padding-left: 16px;
            }

            &:not(:last-child) {
                @include tabletP {
                    margin-right: 1px;
                }
            }
        }

        a#{$_}__item{
            transition:color,background-color 0.3s ease;
            &:hover{
                @include tabletPmin{
                    opacity: 1;
                    background-color: #4d4d4d;
                }
            }
        }

        &__wrap{
            display: flex;
            align-items: center;
            @include tabletPmin{
                flex-wrap: wrap;
                justify-content: center;
                min-height: 90px;
                padding: 10px 5px;
            }

            @include max(1319){
                min-height: 60px;
                padding: 30px 5px;
            }

            @include tabletP{
                padding: 0;
                min-height: 0;
                height: 100%;
            }

        }

        :-ms-lang(x)::-ms-backdrop, #{$_}__wrap{
            padding-bottom: 0;
        }

        &__num{
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            padding-left: 10px;
            width: 45px;
            height: 45px;
            font-family: $fontEB;
            font-weight: bold;
            @include fz(20);
            text-align: left;
            @include tabletP{
                padding-left: 8px;
                width: 40px;
                height: 40px;
                @include fz(14);
                line-height: 1.5;
            }

            &::before{
                content:"";
                position: absolute;
                top: 0;
                left: 0;
                height: 0;
                width: 0px;
                margin: auto;
                border: 22px solid transparent;
                border-left-color: $_pageColor;
                border-top-color: $_pageColor;
                @include tabletP{
                    border-width: 20px;
                }
            }

            span{
                display: block;
                position: relative;

            }

        }

        &__txt{
            display: block;
            position: relative;
            text-shadow: rgba(0,0,0,0.75) 1px 1px 5px;
            @include fz(14);
            font-weight: 500;
            letter-spacing: 0.02em;
            font-family: $baseFontFamilyBold;

            &.-pc{
                @include max(1319){
                    display: none;
                }
            }

            &.-sp{
                @include min(1320){
                    display: none;
                }
                &:not(:last-child) {
                    @include tabletP {
                        display: none;
                    }
                }
            }

            &.-sp02 {
                @include tabletPmin {
                    display: none;
                }
            }

            &.-sizeSp02 {
                @include max(1319) {
                    @include fz(10);
                    letter-spacing: -0.2em;
                }
            }

            &.-sizeSp03 {
                @include tabletP {
                    @include fz(10);
                    letter-spacing: -0.2em;
                    margin: 0 0 0 -10px
                }
            }

            @include max(1319){
                @include fz(12);
            }

            @include tabletP{

            }
        }

        .gPageTop {
            @include tabletP {
                position: absolute;
                bottom: 60px;
                right: 12px;
            }
        }
    }

    @keyframes show {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    $_title01_delay:1;
    .myTitlePage{
        color: #3F3354;
        padding: #{-$headerHeight} 0 0;
        text-align: center;


        @include tabletP {
            padding-top: #{-$headerHeightSp};
        }


        &__inner{
            padding-top: 112px;

            @include tabletP{
                padding-top: 60px;
            }
        }

        &__title{
            opacity: 0;
            @include animation(show,1s,ease,1,forwards,#{$_title01_delay}s);
            @include fz(32);
            color: #10002A;
            font-family: $fontMincho;
            letter-spacing: 0.06em;
            line-height: 1.4;
            @include tabletP{
                @include fz(21);
                letter-spacing: 0.02em;
                line-height: 1.2;

            }
        }

        &__num{
            font-size: 187%;
            font-family: $fontEB;
        }

        &__en{
            opacity: 0;
            @include animation(show,1s,ease,1,forwards,#{$_title01_delay + 1}s);
            @include fz(40);
            font-weight: bold;
            font-family: $fontMontserrat;
            letter-spacing: 0.03em;
            @include tabletP{
                @include fz(21);
            }

        }

    }//.myTitlePage

    $_title02_delay:$_title01_delay + 1.5;

    .myTitlePage02{
        opacity: 0;
        @include animation(show,1s,ease,1,forwards,#{$_title02_delay}s);
        position: relative;
        margin-bottom: 75px;
        padding-top: 43px;
        text-align: center;

        $_:'.myTitlePage02';

        @include tabletP{
            padding-top: 55px;
            margin-bottom: 30px;
        }

        &__num{
            display: block;
            padding-left: 13px;
            width: 80px;
            height: 80px;
            position: absolute;
            top: 0;
            left: -100px;
            color:#fff;
            font-family: $fontEB;
            font-weight: bold;
            @include fz(36);
            text-align: left;
            line-height: 1.4;

            @include tabletP{
                width: 70px;
                height: 70px;
                left: -22px;
                @include fz(30);
            }

            &::before{
                content:"";
                position: absolute;
                top: 0;
                left: 0;
                height: 0;
                width: 0px;
                margin: auto;
                border: 40px solid transparent;
                border-left-color: $_pageColor;
                border-top-color: $_pageColor;
                @include tabletP{
                    border-width: 35px;
                }
            }

            span{
                display: block;
                position: relative;

            }


        }

        &__title{
            margin-bottom: 4px;
            @include fz(32);
            font-weight: bold;
            color: #000;
            font-family: $baseFontFamilyBold;
            @include tabletP{
                margin-bottom: 10px;
                @include fz(20);
            }
        }

        &__txt{
            background-color:rgba(#000000,0.4);
            text-shadow: rgba(0,0,0,0.75) 1px 1px 5px;
            padding: 8px;
            @include fz(22);
            font-weight: 500;
            color:#fff;
            line-height: 1.4;
            @include tabletP{
                @include fz(18);
            }

            @include sphoneSE{
                @include fz(16);
            }


        }

        :-ms-lang(x)::-ms-backdrop, #{$_}__txt{
            padding-top: 20px;
            padding-bottom: 0;
        }

    }//.myTitlePage02

    .myBlockInner{
        background-color: #C4DDE3;
        position: relative;
        padding: 0 100px 75px;
        @include tabletP{
            padding: 0 22px 50px;
            margin-right: -#{$containerPadding}px;
            margin-left: -#{$containerPadding}px;
        }
    }//.myBlockInner

    .myBlockLead{
        $_: ".myBlockLead";
        opacity: 0;
        @include animation(show,1s,ease,1,forwards,#{$_title02_delay + 0.5}s);
        color: #000;
        @include fz(19);
        font-weight: 500;
        line-height: 2.3;
        @include tabletP{
            @include fz(18);
            line-height: 1.7;
        }

        &__txt{
            & + .myBlockLead__txt{
                margin-top: 40px;
            }

            @at-root #{$_root} .myBlockLead__btn + .myBlockLead__txt{
                margin-top: 60px;
            }

            @include tabletPmin{
                padding: 0 31px;
            }

        }

        &__image{
            text-align: center;
            margin-top: 80px;
            @include tabletP {
                margin-top: 20px;
            }
            &-inner {
                display: inline-block;
            }
            &-caption {
                display: block;
                @include fz(12);
                text-align: right;
                @include tabletP {
                    @include fz(10);
                }
            }
        }

        &__btn {
            @include tabletPmin {
                margin: 0 -50px;
            }
            @at-root #{$_root} .myBlockLead__image + .myBlockLead__btn {
                margin-top: 60px;
            }
            &-inner {
                background-color: #3C90A4;
                border: 2px solid #fff;
                display: block;
                color: #fff;
                @include fz(22);
                font-family: $baseFontFamilyBold;
                font-weight: bold;
                text-align: center;
                text-decoration: none;
                line-height: 1.4;
                width: 100%;
                max-width: 550px;
                margin: 0 auto;
                padding: 31px 30px 25px;
                @include tabletP {
                    @include fz(16);
                    max-width: 450px;
                    padding: 14px 15px 14px;
                }
            }
            &-en {
                font-family: $fontEB;
                font-weight: 400;
            }
        }
    }//.myBlockLead

    .myBlockTable {
        margin: 50px -100+33px 0;
        @include tabletP {
            margin: 20px 0 0;
        }
        &__txt {
            font-family: $baseFontFamilyBold;
            font-weight: 600;
            line-height: (33.25 / 19);
            text-align: center;
            margin-bottom: 44px;
            @include tabletP {
                margin-bottom: 20px;
            }
        }
        &__table {
            background-color: #fff;
            table-layout: fixed;
            max-width: 804px;
            margin: 0 auto;
            tbody {
                tr {
                    th, td {
                        border: 3px solid #1D4859;
                        @include fz(18);
                        font-family: $baseFontFamilyBold;
                        font-weight: 600;
                        text-align: center;
                        padding: 12px 15px 11px;
                        @include tabletP {
                            border-width: 2px;
                            @include fz(13);
                            padding: 6px 10px 5px;
                        }
                    }
                    th {
                        background-color: #1D4859;
                        color: #fff;
                    }
                }
            }
        }
        &__note {
            text-align: right;
            @include fz(14);
            max-width: 804px;
            margin: 4px auto 0;
            @include tabletP {
                @include fz(12);
            }
        }
    }//.myBlockTable

    .myBlockSupport {
        padding: 92px 0 0;
        @include tabletP {
            padding: 61px 0 0;
        }
        &__txtbox {
            max-width: 930px;
            margin: 0 auto;
        }
        &__txt {
            color: #000;
            @include fz(19);
            font-weight: 500;
            line-height: (35 / 19);
            @include tabletP {
                @include fz(17);
                line-height: (35 / 17);
            }
            &.-size02 {
                @include fz(20);
                font-family: $baseFontFamilyBold;
                font-weight: bold;
                line-height: (35 / 20);
                @include tabletP {
                    @include fz(18);
                    line-height: (35 / 18);
                }
            }
        }
        &-point {
            margin: 117px 0;
            @include tabletP {
                margin: 73px 0;
            }
            &__list {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                @include tabletP {
                    margin: 0 -#{$containerPadding}px;
                }
            }
            &__item {
                position: relative;
                color: #fff;
                width: calc(50% - 45px);
                padding: 65px 0 79px;
                @include tabletP {
                    width: 100%;
                    padding: 65px 24px 69px;
                }
                &::before {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 0;
                    width: 50vw;
                    height: 100%;
                    @include tabletP {
                        left: 0;
                        width: 100%;
                    }
                }
                &.-item01 {
                    &::before {
                        background-color: #5F939A;
                        @include tabletPmin {
                            right: -45px;
                        }
                    }
                }
                &.-item02 {
                    &::before {
                        background-color: #A0937D;
                        @include tabletPmin {
                            left: -45px;
                        }
                    }
                }
            }
            &-title {
                position: relative;
                @include fz(21);
                line-height: (31 / 21);
                margin-bottom: 17px;
                padding: 0 0 15px 12px;
                @include tabletP {
                    @include fz(18);
                    line-height: (31 / 18);
                    margin-bottom: 16px;
                    padding: 0 0 15px 21px;
                }
                &::after {
                    content: "";
                    background-color: #fff;
                    display: block;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 93%;
                    height: 2px;
                    @include tabletP {
                        width: 100%;
                    }
                }
                &__txt01,
                &__txt02 {
                    display: block;
                }
                &__txt01 {
                    font-weight: 600;
                }
                &__txt02 {
                    font-family: $fontInter;
                    font-weight: 600;
                }
            }
            &__txt {
                position: relative;
                @include fz(19);
                font-weight: 500;
                line-height: (39 / 19);
                padding-left: 10px;
                @include tabletP {
                    @include fz(17);
                    line-height: (39 / 17);
                    padding: 0 17px;
                }
            }
        }//.-point
        &-flow {
            &__title {
                color: #000;
                @include fz(21);
                font-weight: 600;
                line-height: 1.4;
                text-align: center;
                margin-bottom: 74px;
                @include tabletP {
                    color: #000;
                    @include fz(18);
                    margin-bottom: 55px;
                }
                span {
                    border-bottom: 1px solid #000;
                    display: inline-block;
                    padding-bottom: 13px;
                    @include tabletP {
                        padding-bottom: 9px;
                    }
                }
            }
            &__image01,
            &__image02 {
                text-align: center;
            }
            &__image01 {
                margin-bottom: 93px;
                @include tabletP {
                    margin-bottom: 47px;
                }
            }
        }
    }//.myBlockSupport

    .myBlockService {
        $_: ".myBlockService";
        background-color: #B2AB8C;
        margin-top: 102px;
        padding: 93px 0 130px;
        @include tabletP {
            margin-top: 46px;
            padding: 46px 0 46px;
        }
        &__title {
            color: #000;
            @include fz(23);
            font-weight: 600;
            line-height: 1.4;
            margin-bottom: 36px;
            @include tabletP {
                @include fz(16);
                margin-bottom: 24px;
            }
            &::after {
                content: "";
                background-color: #000;
                display: block;
                width: 250px;
                height: 1px;
                margin-top: 8px;
                @include tabletP {
                    display: none;
                }
            }
            &.-colorWhite {
                color: #fff;
                &::after {
                    background-color: #fff;
                }
            }
            &.-alignCenter {
                text-align: center;
                &::after {
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
        &__txt {
            @include fz(19);
            letter-spacing: -0.02em;
            font-weight: 500;
            line-height: (39 / 19);
            @include tabletP {
                @include fz(17);
                line-height: (35 / 17);
            }
        }
        &__service {
            text-align: center;
            margin-top: 69px;
            @include tabletP {
                margin: 60px -#{$containerPadding}px 0;
            }
        }
        &__formation {
            background-color: #fff;
            text-align: center;
            margin-top: 100px;
            padding: 86px #{$containerPadding}px 65px;
            @include tabletP {
                margin: 60px -24px 0;
                padding-top: 38px;
                padding-bottom: 33px;
            }
        }
    }//.myBlockService

    .myList01 {
        margin-top: 17px;
        @include tabletPmin {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
        @include tabletP {
            margin-top: 10px;
        }
        &__item {
            text-align: center;
            margin-top: 17px;
            @include tabletPmin {
                max-width: 50%;
            }
        }
    }//.myList01

    .myImageList01 {
        margin: 40px 0 80px;
        padding: 0 33px;
        @include tabletP {
            margin: 20px 0 40px;
        }
        &__item {
            text-align: center;
            @include tabletPmin {
                width: percentage(600px/(1000px-33px*2));
            }
            &:not(:last-child) {
                margin-bottom: percentage(-42px/(1000px-33px*2));
                @include tabletP {
                    margin-bottom: 20px;
                }
            }
            &:nth-child(even) {
                margin-left: auto;
                margin-right: 0;
            }
        }
    }//.myImageList01
}
