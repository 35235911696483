@charset "utf-8";
/*
#overview
modList
*/

/*
#styleguide
modList　modIndent

```
<ul class="modListNormal">
    <li>リスト</li>
    <li>リスト</li>
</ul>
<ol class="modListNormal">
    <li>リスト</li>
    <li>リスト</li>
</ol>
<ul class="modIndent01">
    <li>※テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。</li>
    <li>※リスト</li>
</ul>
<ul class="modIndent02">
    <li>１.　テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。</li>
    <li>２.　リスト</li>
</ul>
```
*/

.modListNormal { margin: 0 0 0 2em; }

ul.modListNormal {
	li{
		&:before {
			content: "\0030fb";
			display: inline-block;
			margin-left:-2em;
			text-align:center;
			width:2em;
		}
	}
}

ol.modListNormal li { list-style:decimal; }

/* indent */
.modIndent01 li {
	margin: 0 0 0 1em;
	text-indent: -1em;
}

.modIndent02 li {
	margin: 0 0 0 2.5em;
	text-indent: -2.5em;
}

.modDlForm{
	margin:0 0 20px;
	dt{
		clear:both;
		float:left;
		padding:33px 0 20px 63px;
		position:relative;
		width:16em;
		@include tabletP{
			float:none;
			padding:33px 0 0 63px;
			width:auto;
		}
		&.required:before,
		&.any:before{
			color:#FFF;
			@include fz(11);
			left:0;
			padding:2px 10px;
			position:absolute;
			top:33px;
		}
		&.required:before{
			background:$colorStrong;
			content:"必須";
		}
		&.any:before{
			background:#999999;
			content:"任意";
		}
	}
	dd{
		border-bottom:solid 1px #ccc;
		padding:10px 0;
		padding-left:17em;
		@include tabletP{
			padding:10px 0;
		}
		.parts{
			padding:10px 0;
			textarea,
			input[type="text"],
			input[type="email"] {
				width:100%;
			}
			&.radio_inline,
			&.check_inline {
				> div{
					display:inline-block;
				}
			}
			&.name{
				display:flex;
				justify-content:space-between;
				>div{
					width:48.5%;
				}
			}
			&.post,
			&.tel {
				display:flex;
				.hyphen{
					padding:10px 10px 0 10px;
					@include sphoneP{
						padding:10px 5px 0 5px;
					}
				}
			}
			&.password{
				input{
					max-width:300px;
					width:100%;
					@include sphoneP{
						max-width:none;
					}
				}
				.text{
					padding:10px 0;
				}
			}
		}
	}
	.validationError{
		color:$colorStrong;
	}
}

.modListBreadcrumb {
	&__item {
		display: inline;
		@include tabletP{
		}
		&:not(:last-child):after {
			content:'\3E';
			margin: 0 8px;
		}
		&.-home {
			&:before{
				display: none;
			}
		}
	}
	&__link {
		&:hover{
		}
	}
}//.modListBreadcrumb
