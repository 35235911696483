@charset "utf-8";

/*
fukuoka
*/
$id:fukuoka;
$cmnPath:'../img/common/';
$imgPath:'../img/#{$id}/';
$_pageColor: #10002A;
body#page_#{$id} {

    $_root:&;

    .gHeader {
        background-color: rgba(#000, .5);
    }

    .myWrapper{
        padding-top: 91px;
        padding-bottom: (180-35)+px;
        overflow: hidden;
        @include tabletP{
            padding-top: 25px;
            padding-bottom: 90px;
        }

    }

    .myListMenu{
        $_:'.myListMenu';


        &__item{
            &:not(:last-child){
                margin-bottom: 50px;
                @include tabletP{
                    margin-bottom: 30px;
                }
            }
        }

        &__inner{
            display: block;
            position: relative;
            transition:color,background-color 0.3s ease;
            background-color: #006094;
            box-shadow: nth($mainColor,1) 0 0 0 1px;
            position: relative;
            padding: 30px 100px 19px;
            text-decoration: none;
            color:#fff;
            text-align: center;

            @include tabletP{
                padding: 20px 40px 10px 30px;
                margin-right: -#{$containerPadding}px;
                margin-left: -#{$containerPadding}px;
            }


            &::after{
                content:"";
                position: absolute;
                top: 0;
                right: 20px;
                bottom: 0;
                margin: auto 0;
                background-image: url(../img/fukuoka/arrow.svg);
                background-position: 0 0;
                background-repeat: no-repeat;
                background-size: 55px 55px;
                width: 55px;
                height: 55px;
                @include tabletP{
                    background-size: 25px 25px;
                    width: 25px;
                    height: 25px;
                    right: 10px;
                }
            }
        }

        a#{$_}__inner{
            &:hover{
                opacity: 1;
                background-color: #4D4D4D;
            }
        }

        &__num{
            display: block;
            width: 80px;
            height: 80px;
            position: absolute;
            top: 0;
            left: 0;
            padding-left: 13px;
            font-family: $fontEB;
            @include fz(36);
            font-weight: bold;
            line-height: 1.4;
            text-align: left;
            @include tabletP{
                width: 70px;
                height: 70px;
                @include fz(30);
            }

            &::before{
                content:"";
                position: absolute;
                bottom: 0;
                left: 0;
                height: 0;
                width: 0px;
                margin: auto;
                border: 40px solid transparent;
                border-left-color: $_pageColor;
                border-top-color: $_pageColor;
                @include tabletP{
                    border-width: 35px;
                }
            }

            span{
                display: block;
                position: relative;
            }

        }

        &__title{
            display: block;
            margin-bottom: 9px;
            @include fz(32);
            font-weight: bold;
            font-family: $baseFontFamilyBold;
            text-shadow: rgba(0,0,0,0.75) 1px 1px 5px;
            letter-spacing: 0.02em;
            line-height: 1.4;
            @include tabletP{
                @include fz(20);
                padding: 0 1em;
            }
        }

        &__txt{
            display: block;
            padding: 8px;
            background-color: rgba(#000000,0.4);
            @include fz(22);
            font-weight: 500;
            text-shadow: rgba(0,0,0,0.75) 1px 1px 5px;
            letter-spacing: 0.02em;
            line-height: 1.4;
            @include tabletP{
                @include fz(18);
            }

        }

        :-ms-lang(x)::-ms-backdrop, #{$_}__txt{
            padding-top: 20px;
            padding-bottom: 0;
        }

    }

    .myBnrNext {
        text-align: center;
        margin-top: 50px;
        @include tabletP {
            margin-top: 30px;
        }
    }

    .myNav{
        position: fixed;
        z-index: 4;

        @include print{
            display: none;
        }

        @include tabletPmin{
            top: 0;
            bottom: 0;
            right: 0;
            width: 170px;
        }

        @include max(1319){
            width: 50px;
        }


        $_:'.myNav';

        &[data-scroll="true"]{
            @include tabletP{
                visibility: hidden;
                opacity: 0;
            }
        }

        &[data-scroll="false"]{
            @include tabletP{
                opacity: 1;
                visibility: visible;
            }
        }


        @include tabletP{
            width: auto;
            bottom: calc(23px + 8px + 2.8em + 20px);
            left: 8px;
            right: 8px;
            transition:all 0.3s ease;
        }

        &__body{
            @include tabletPmin{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                height: 100%;
            }
        }

        &__inner{
            box-shadow: rgba(#000,0.75) 1px 1px 3px;
            @include tabletPmin{
                width: 100%;
            }

            @include tabletP{
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
            }

        }

        &__item{
            display: block;
            position: relative;
            background-color: #006094;
            color:#fff;
            line-height: 1.3;
            text-decoration: none;
            text-align: center;
            @include tabletPmin{
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                border-top: solid 1px $_pageColor;
            }
            @include tabletP{
                flex:1;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: flex-end;
                padding: 10px 4px 6px 25px;
                box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.75);
                line-height: 1;
            }

            @include sphoneSE{
                padding-left: 16px;
            }

            &:not(:last-child) {
                @include tabletP {
                    margin-right: 1px;
                }
            }
        }

        a#{$_}__item{
            transition:color,background-color 0.3s ease;
            &:hover{
                @include tabletPmin{
                    opacity: 1;
                    background-color: #4d4d4d;
                }
            }
        }

        &__wrap{
            display: flex;
            align-items: center;
            @include tabletPmin{
                flex-wrap: wrap;
                justify-content: center;
                min-height: 90px;
                padding: 10px 5px;
            }

            @include max(1319){
                min-height: 60px;
                padding: 30px 5px;
            }

            @include tabletP{
                padding: 0;
                min-height: 0;
                height: 100%;
            }

        }

        :-ms-lang(x)::-ms-backdrop, #{$_}__wrap{
            padding-bottom: 0;
        }

        &__num{
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            padding-left: 10px;
            width: 45px;
            height: 45px;
            font-family: $fontEB;
            font-weight: bold;
            @include fz(20);
            text-align: left;
            @include tabletP{
                padding-left: 8px;
                width: 40px;
                height: 40px;
                @include fz(14);
                line-height: 1.5;
            }

            &::before{
                content:"";
                position: absolute;
                top: 0;
                left: 0;
                height: 0;
                width: 0px;
                margin: auto;
                border: 22px solid transparent;
                border-left-color: $_pageColor;
                border-top-color: $_pageColor;
                @include tabletP{
                    border-width: 20px;
                }
            }

            span{
                display: block;
                position: relative;

            }

        }

        &__txt{
            display: block;
            position: relative;
            text-shadow: rgba(0,0,0,0.75) 1px 1px 5px;
            @include fz(14);
            font-weight: 500;
            letter-spacing: 0.02em;
            font-family: $baseFontFamilyBold;

            &.-pc{
                @include max(1319){
                    display: none;
                }
            }

            &.-sp{
                @include min(1320){
                    display: none;
                }
                &:not(:last-child) {
                    @include tabletP {
                        display: none;
                    }
                }
            }

            &.-sp02 {
                @include tabletPmin {
                    display: none;
                }
            }

            &.-sizeSp02 {
                @include max(1319) {
                    @include fz(10);
                    letter-spacing: -0.2em;
                }
            }

            &.-sizeSp03 {
                @include max(1319) {
                    @include fz(12);
                    letter-spacing: -0.02em;
                }
                @include tabletP {
                    @include fz(10);
                    letter-spacing: 0.02em;
                }
            }

            @include max(1319){
                @include fz(12);
            }

            @include tabletP{

            }
        }

        .gPageTop {
            @include tabletP {
                position: absolute;
                bottom: 60px;
                right: 12px;
            }
        }
    }

    @keyframes show {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    $_title01_delay:1;
    .myTitlePage{
        color: $_pageColor;
        padding: #{-$headerHeight} 0 0;
        text-align: center;


        @include tabletP {
            padding-top: #{-$headerHeightSp};
        }


        &__inner{
            padding-top: 112px;

            @include tabletP{
                padding-top: 60px;
            }
        }

        &__title{
            opacity: 0;
            @include animation(show,1s,ease,1,forwards,#{$_title01_delay}s);
            @include fz(32);
            color:$_pageColor;
            font-family: $fontMincho;
            letter-spacing: 0.06em;
            line-height: 1.4;
            @include tabletP{
                @include fz(21);
                letter-spacing: 0.02em;
                line-height: 1.2;

            }
        }

        &__num{
            font-size: 187%;
            font-family: $fontEB;
        }

        &__en{
            opacity: 0;
            @include animation(show,1s,ease,1,forwards,#{$_title01_delay + 1}s);
            @include fz(40);
            font-weight: bold;
            font-family: $fontMontserrat;
            letter-spacing: 0.03em;
            @include tabletP{
                @include fz(21);
            }

        }

    }//.myTitlePage

    $_title02_delay:$_title01_delay + 1.5;

    .myTitlePage02{
        opacity: 0;
        @include animation(show,1s,ease,1,forwards,#{$_title02_delay}s);
        position: relative;
        margin-bottom: 75px;
        padding-top: 43px;
        text-align: center;

        $_:'.myTitlePage02';

        @include tabletP{
            padding-top: 55px;
            margin-bottom: 30px;
        }

        &__num{
            display: block;
            padding-left: 13px;
            width: 80px;
            height: 80px;
            position: absolute;
            top: 0;
            left: -100px;
            color:#fff;
            font-family: $fontEB;
            font-weight: bold;
            @include fz(36);
            text-align: left;
            line-height: 1.4;

            @include tabletP{
                width: 70px;
                height: 70px;
                left: -22px;
                @include fz(30);
            }

            &::before{
                content:"";
                position: absolute;
                top: 0;
                left: 0;
                height: 0;
                width: 0px;
                margin: auto;
                border: 40px solid transparent;
                border-left-color: $_pageColor;
                border-top-color: $_pageColor;
                @include tabletP{
                    border-width: 35px;
                }
            }

            span{
                display: block;
                position: relative;

            }


        }

        &__title{
            margin-bottom: 4px;
            @include fz(32);
            font-weight: bold;
            color:nth($mainColor,1);
            font-family: $baseFontFamilyBold;
            @include tabletP{
                margin-bottom: 10px;
                @include fz(20);
            }
        }

        &__txt{
            background-color:rgba(#000000,0.4);
            text-shadow: rgba(0,0,0,0.75) 1px 1px 5px;
            padding: 8px;
            @include fz(22);
            font-weight: 500;
            color:#fff;
            line-height: 1.4;
            @include tabletP{
                @include fz(18);
            }

            @include sphoneSE{
                @include fz(16);
            }


        }

        :-ms-lang(x)::-ms-backdrop, #{$_}__txt{
            padding-top: 20px;
            padding-bottom: 0;
        }

    }//.myTitlePage02

    .myBlockInner{
        background-color: #F4F9FF;
        position: relative;
        padding: 0 100px 75px;
        @include tabletP{
            padding: 0 22px 50px;
            margin-right: -#{$containerPadding}px;
            margin-left: -#{$containerPadding}px;
        }
        &.-color02 {
            background-color: #F7FAFF;
        }
    }//.myBlockInner

    .myBlockLead{
        $_: ".myBlockLead";
        opacity: 0;
        @include animation(show,1s,ease,1,forwards,#{$_title02_delay + 0.5}s);
        @include fz(19);
        font-weight: 500;
        line-height: 2.3;
        @include tabletP{
            @include fz(18);
            line-height: 1.7;
        }

        &__txt{
            & + .myBlockLead__txt{
                margin-top: 40px;
            }

            @include tabletPmin{
                padding: 0 31px;
            }

        }

        &__image{
            text-align: center;
            flex-shrink: 0;
            @include tabletP {
                margin-top: 20px;
            }
            &.-type02 {
                margin: 0 -100px;
                @include tabletP {
                    margin: 0 -#{$containerPadding}px;
                }
            }
        }

        &-box {
            padding-bottom: 82px;
            @include tabletLmin {
                display: flex;
            }
            @include tabletL {
                padding-bottom: 50px;
            }
            #{$_}__image {
                @include tabletLmin {
                    width: 40%;
                    margin: 10px 10px 0 0;                    
                }
                @include tabletL {
                    margin-top: 20px;
                }
            }
            
            &__detail {
                flex-basis: 1;
            }
        }

    }//.myBlockLead

    .myBlockSection{
        $_: ".myBlockSection";
        padding: 40px 50px;
        background-color: #fff;
        border-color: #B49F86;
        border-top-style: solid;
        border-top-width: 5px;
        @include fz(19);
        font-weight: 500;
        line-height: 2.3;
        @include tabletP{
            @include fz(18);
            line-height: 1.7;
        }

        & + .myBlockSection{
            margin-top: 90px;

            @include tabletP{
                margin-top: 40px;
            }

        }

        @include tabletP{
            padding: 20px 16px 30px;
        }

        &__txt {
            & + #{$_}__txt {
                margin-bottom: 45px;
            }
        }

        &__note {
            @include fz(17);
        }

        &__reference {
            @include fz(12);
            line-height: (33 / 13);
        }

        &__image{
            flex-shrink: 0;
            text-align: center;
            @at-root #{$_root} #{$_}__txt + #{$_}__image {
                margin-top: 66px;
                @include tabletP {
                    margin-top: 30px;
                }
            }
            @at-root #{$_root} #{$_}__reference + #{$_}__image {
                margin-top: 66px;
                @include tabletP {
                    margin-top: 30px;
                }
            }
            @at-root #{$_root} #{$_}-box__inner + #{$_}__image {
                margin-top: 30px;
            }
            @at-root #{$_root} #{$_}-box__detail + #{$_}__image {
                @include tabletP {
                    margin-top: 30px;
                }
            }
            & + #{$_}__image {
                margin-top: 110px;
                @include tabletP {
                    margin-top: 50px;
                }
            }
        }


        &-box {
            $_:'.myBlockSection-box';
    
            &:not(:first-child) {
                margin-top: 89px;
                @include tabletP {
                    margin-top: 40px;
                }
            }
    
            &__inner {
                @include tabletPmin{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                }
            }
    
            &__detail{
                @include tabletPmin{
                    flex:1;
                    padding-right: 40px;
                }
            }
        }
    }//.myBlockSection

    .myTitle01{
        margin-bottom: 50px;
        font-weight: bold;
        @include fz(21);
        font-family: $baseFontFamilyBold;
        line-height: 1.5;

        @include tabletP{
            margin-bottom: 30px;
            @include fz(18);
        }

    }//.myTitle01

    .myTitle02{
        margin-left: -9px;
        @include tabletP{
            width: 60%;
        }
    }//.myTitle02

    .myTitle03{
        margin-bottom: 15px;
        @include fz(19);
        font-weight: 500;
        @include tabletP{
            @include fz(16);
        }
    }//.myTitle03

    .myList01 {
        &-item {
            &__title {
                font-weight: bold;
            }
        }
    }//.myList01

    .myListMeasure {
        margin-top: 68px;
        @include tabletP {
            margin-top: 30px;
        }
        &__title {
            color: #0071A9;
            @include fz(19);
            font-family: $baseFontFamilyBold;
            font-weight: bold;
            text-align: center;
            margin-bottom: 20px;
        }
        &__item {
            text-align: center;
            &:not(:last-child) {
                margin-bottom: 45px;
                @include tabletP {
                    margin-bottom: 20px;
                }
            }
        }
        &__note {
            @include fz(16);
            font-weight: 600;
            text-align: right;
            margin-top: 56px;
            @include tabletP {
                margin-top: 24px;
            }
        }
    }//.myListMeasure
}
