@charset "utf-8";

.modParts{
	margin-bottom:20px;
}

.modPartsImage{
	color:#959595;
	text-align:center;
	@include fz(11);
	.caption{
		margin:5px 0 0;
	}
	a:hover{
		opacity:0.8;
	}
}

.modPartsYoutube {
	margin-left:auto;
	margin-right:auto;
	max-width:560px;
	@include tabletP{
		padding-top: 56.25%;
		position: relative;
		width: 100%;
		iframe {
			height: 100% !important;
			position: absolute;
			right: 0;
			top: 0;
			width: 100% !important;
		}
	}
}

.modPartsTxt{
	iframe{
		height:400px;
		width:100%;
		@include tabletP{
			height:250px;
		}
	}
	ul {
		margin-left: 2em;
		li {
			list-style: disc;
		}
	}
	ol {
		margin-left: 2.2em;
		li {
			list-style: decimal;
		}
	}
	strong,
	th {
		font-weight:bold;
	}
	.aligncenter {
		display: block;
		margin-left: auto;
		margin-right: auto;
	}
	img {
		&.alignright {
			display: inline;
			margin: 0 0 2px 7px;
			padding: 4px;
		}
		&.alignleft {
			display: inline;
			margin: 0 7px 2px 0;
			padding: 4px;
		}
	}
	.alignright {
		float: right;
	}
	.alignleft {
		float: left;
	}
	strong{
		font-weight:bold;
	}

}
