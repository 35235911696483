@charset "utf-8";

/*
#overview
modTxt
*/

/*
#styleguide
modTxtCatch

色はclass付与で対応
各ページclass付与して位置調整
```

    <div class="modTxtCatch -color01" data-aos="fade-up" data-aos-anchor-placement="center-bottom">
        <div class="modTxtCatch__inner">
            将来を守る資産形成。<br>
            「不動産投資」という選択。
        </div>
        <div class="modTxtCatch-frame -left01"><span class="modTxtCatch-frame__item"></span></div>
        <div class="modTxtCatch-frame -left02"><span class="modTxtCatch-frame__item"></span></div>
        <div class="modTxtCatch-frame -right01"><span class="modTxtCatch-frame__item"></span></div>
        <div class="modTxtCatch-frame -right02"><span class="modTxtCatch-frame__item"></span></div>

    </div>

    <div class="modTxtCatch -reason -color02" data-aos="fade-up" data-aos-anchor-placement="center-bottom">
        <div class="modTxtCatch__inner">LANDICが選ばれる理由</div>
        <div class="modTxtCatch-frame -left01"><span class="modTxtCatch-frame__item"></span></div>
        <div class="modTxtCatch-frame -left02"><span class="modTxtCatch-frame__item"></span></div>
        <div class="modTxtCatch-frame -right01"><span class="modTxtCatch-frame__item"></span></div>
        <div class="modTxtCatch-frame -right02"><span class="modTxtCatch-frame__item"></span></div>
    </div>
    ```
*/

@keyframes catchLeft01 {
    0% {
        width: 0;
        left: 100%;
        opacity: 0;
    }

    100% {
        width: 100%;
        left:0;
        opacity: 1;
    }
}

@keyframes catchLeft02{
    0% {
        height: 0;
        opacity: 0;
    }

    100% {
        height: 100%;
        opacity: 1;
    }
}


@keyframes catchRight01{
    0% {
        height: 0;
        bottom: 100%;
        opacity: 0;
    }

    100% {
        height: 100%;
        bottom: 0;
        opacity: 1;
    }
}

@keyframes catchRight02{
    0% {
        width: 0;
        opacity: 0;
    }

    100% {
        width: 100%;
        opacity: 1;
    }
}

@keyframes catchFadeIn{
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
.modTxtCatch{
    position: relative;
    margin-bottom: 190px;

    @include tabletP{
        margin-bottom: 45px;
    }

    &.-reason {
        margin-bottom: 215px;

        @include tabletP{
            margin-bottom: 52px;
        }
    }

    &.-faq {
        margin-bottom: 92px;

        @include tabletP{
            margin-bottom: 46px;
        }
    }

    $_root:&;

    $_load:1;
    $_spW:0.2;
    $_spH:0.2;
    $_anime:$easeOutQuart;

    $_color:(
        color01:(
            bg:#ffbd9b,
            color:$baseColor
        ),
        color02:(
            bg:#293B5F,
            color:#0A1D37
        )
    );

    &__inner{
        opacity: 0;
        padding: 50px 10px 54px;
        text-align: center;
        @include fz(32);
        font-weight: 500;
        font-family: $fontMincho;

        @each $name,$data in $_color {
            #{$_root}.-#{$name} &{
                color: map-get( $data, color );
            }
        }

        @at-root #{$_root}.aos-animate &{
            @include animation(catchFadeIn,2s,ease,1,forwards,#{$_load + $_spW*1 + $_spH*1}s);
        }

        @include tabletP{
            padding: 30px 10px;
        }

        @include sphoneP{
            @include fz(18);

        }

        @at-root #{$_root}.-reason & {
            padding: 50px 10px 80px;
            @include tabletP {
                padding: 46px 10px;
            }
        }

        @at-root #{$_root}.-faq & {
            padding: 50px 10px 80px;
            @include tabletP {
                padding: 46px 10px;
            }
        }
    }

    &-frame{
        $_:&;
        position: absolute;


        &.-left01,
        &.-right02
        {
            width: 200px;
            height: 8px;
            @include tabletP{
                width: get_vw(75,375);
                height: 3px;
            }
        }

        &.-left01 #{$_}__item,
        &.-right02 #{$_}__item
        {
            height: 100%;
        }

        &.-left02,
        &.-right01
        {
            width: 8px;
            height: 90px;
            @include tabletP{
                width: 3px;
                height: get_vw(33,375);
            }

            @at-root #{$_root}.-reason & {
                @include tabletPmin {
                    height: 94px;
                }
            }

            @at-root #{$_root}.-faq & {
                @include tabletPmin {
                    height: 94px;
                }
            }
        }

        &.-left02 #{$_}__item,
        &.-right01 #{$_}__item
        {
            width: 100%;
        }

        &.-left01 ,
        &.-left02 ,{
            top: 0;
            left: 0;
        }

        &.-right01 ,
        &.-right02 ,{
            right: 0;
            bottom: 0;
        }

        &.-left01 #{$_}__item,
        &.-left02 #{$_}__item,{
            top: 0;
            left: 0;
        }

        &.-right01 #{$_}__item,
        &.-right02 #{$_}__item,{
            right: 0;
            bottom: 0;
        }

        @at-root #{$_root}.aos-animate #{$_}.-left01 #{$_}__item{
            @include animation(catchLeft01,#{$_spW}s,#{$_anime},1,forwards,#{$_load}s);
        }

        @at-root #{$_root}.aos-animate #{$_}.-left02 #{$_}__item{
            @include animation(catchLeft02,#{$_spH}s,#{$_anime},1,forwards,#{$_load + $_spW}s);
        }

        @at-root #{$_root}.aos-animate #{$_}.-right01 #{$_}__item{
            @include animation(catchRight01,#{$_spH}s,#{$_anime},1,forwards,#{$_load + $_spW + $_spH}s);
        }

        @at-root #{$_root}.aos-animate #{$_}.-right02 #{$_}__item{
            @include animation(catchRight02,#{$_spW}s,#{$_anime},1,forwards,#{$_load + $_spW + $_spH + $_spH}s);
        }

        &__item{
            display: block;
            background-color: #000;
            position: absolute;

            @each $name,$data in $_color {
                #{$_root}.-#{$name} &{
                    background-color: map-get( $data, bg );
                }
            }


        }


    }


}//.modTxtCatch
