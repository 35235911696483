@charset "utf-8";
#contents {}
.gContents {
    position: relative;
    padding-top: $headerHeight;
    z-index: 1;
    @include tabletP {
        padding-top: $headerHeightSp;
    }
}

.gCNav {
    $_: &;
    background-color: #fff;
    padding: 35px 0;
    position: relative;
    @at-root #page_top & {
        background: none;
        z-index: 2;
    }
    @include tabletP {
        display: none;
    }
    &__group {
        &:not(:last-child) {
            margin-bottom: 16px;
        }
    }
    &__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    &__item {
        position: relative;
        min-width: 180px;
        @include max(1076) {
            min-width: get_vw(180, 1076)
        }
        &:not(:last-child) {
            &::after {
                content: "";
                background-color: #4D4D4D;
                position: absolute;
                top: 0;
                right: 0;
                width: 1px;
                height: 100%;
            }
        }
        &.-disabled {
            #{$_}__txt01,
            #{$_}__txt02 {
                opacity: 0.6;
            }
        }
    }
    &__link {
        display: flex;
        flex-direction: column;
        position: relative;
        text-decoration: none;
        height: 100%;
        padding: 2px 16px 10px;
        @include max(1076) {
            padding-left: get_vw(16, 1076);
            padding-right: get_vw(16, 1076);
        }
    }
    &__txt01,
    &__txt02 {
        display: block;
        color: #4d4d4d;
        line-height: 1.4;
        text-align: center;
    }
    &__txt01 {
        font-family: $fontInter;
        @include fz(16);
        font-weight: 500;
        @include max(1076) {
            @include fz_vw(16, 1076);
        }
    }
    &__txt02 {
        @include fz(14);
        font-weight: 500;
        margin-top: 10px;
        @include max(1076) {
            @include fz_vw(14, 1076);
            margin-top: get_vw(10, 1076);
        }
        &.-sizeSmall {
            transform: scale(.85);
            margin: 2px -10px 0;
            @include max(1076) {
                @include fz_vw(14, 1076);
                margin: get_vw(-2, 1076) get_vw(-10, 1076) 0;
            }
        }
    }
}//.gCNav
