@charset "utf-8";

/*
#overview
modNav
*/

/*
#styleguide
modNav01

背景色はclass付与で対応
SP時の並びはclass付与で対応（通常2列、-spType02で1列）
各ページclass付与して位置調整
```

<div id="nav" class="modNav01 -column04 -color01" data-aos="fade-up" data-aos-anchor-placement="center-bottom" data-height>
    <a href="#merit01" class="modNav01__item">
        <div class="modNav01__inner">
            <div class="modNav01__head" data-height__item>
                <span class="modNav01__main">私的年金として</span>
                <span class="modNav01__sub">(定期収入として)</span>
            </div>
            <span class="modNav01__en">Private Pension</span>
        </div>
    </a>
    <a href="#merit02" class="modNav01__item">
        <div class="modNav01__inner">
            <div class="modNav01__head" data-height__item>
                <span class="modNav01__main">節税効果</span>
                <span class="modNav01__sub">(所得税･住民税／相続税)</span>
            </div>
            <span class="modNav01__en">Tax-effecient</span>
        </div>
    </a>
    <a href="#merit03" class="modNav01__item">
        <div class="modNav01__inner">
            <div class="modNav01__head" data-height__item>
                <span class="modNav01__main">生命保険代わりに</span>
            </div>
            <span class="modNav01__en">As life insurance</span>
        </div>
    </a>
    <a href="#merit04" class="modNav01__item">
        <div class="modNav01__inner">
            <div class="modNav01__head" data-height__item>
                <span class="modNav01__main">売却できる実物資産</span>
            </div>
            <span class="modNav01__en">Assets</span>
        </div>
    </a>
</div>

<div id="nav" class="modNav01 -reason -column03 -color02 -spType02" data-aos="fade-up" data-aos-anchor-placement="center-bottom" data-height>
    <a href="#fukuoka" class="modNav01__item">
        <div class="modNav01__inner">
            <div class="modNav01__head" data-height__item>
                <span class="modNav01__main">福岡の将来性と魅力</span>
            </div>
            <span class="modNav01__en">Charms of Fukuoka</span>
        </div>
    </a>
    <a href="#quality" class="modNav01__item">
        <div class="modNav01__inner">
            <div class="modNav01__head" data-height__item>
                <span class="modNav01__main">総合的品質</span>
            </div>
            <span class="modNav01__en">Quality</span>
        </div>
    </a>
    <a href="#support" class="modNav01__item">
        <div class="modNav01__inner">
            <div class="modNav01__head" data-height__item>
                <span class="modNav01__main">安心の保証とフォロー</span>
            </div>
            <span class="modNav01__en">Support</span>
        </div>
    </a>
</div>
```
*/

.modNav01{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 121px;

    $_:&;
    $_color:(
        color01:
            (
                 #ffbd9b,   // bg
                 #0A1D37,   // bg-hover
                 (rgba(0,0,0,0.5) 0 0 10px, rgba(0,0,0,1) 0 0 5px)    // text-shadow
            ),
        color02:
            (
                 #B2AB8C,
                 #293B5F,
                 0px 0px 5px rgba(0, 0, 0, 0.75)
            )
    );

    &.-column03{
        $_w: percentage(393.33px/1200px);

        @include tabletPmin{

            &::after{
                content:"";
                display: block;
                width: #{$_w};
            }

            &::before{
                content:"";
                display: block;
                width: #{$_w};
                order:1;
            }
        }

        #{$_}__item{
            width: #{$_w};
            @include tabletP{
                width: 49%;
                &:nth-child(-n + 2){
                    margin-top: 0;
                }
            }
        }
    }

    &.-column04{

        $_w:'24.4%';

        @include tabletPmin{

            &::after{
                content:"";
                display: block;
                width: #{$_w};
            }

            &::before{
                content:"";
                display: block;
                width: #{$_w};
                order:1;
            }
        }

        #{$_}__item{
            width: #{$_w};
            @include tabletP{
                width: 49%;
                &:nth-child(-n + 2){
                    margin-top: 0;
                }
            }
        }

    }


    @include tabletP{
        margin-bottom: 75px;

        @at-root #{$_}.-spType02 {
            margin: 0 -23px 87px;
        }
    }

    &__item{
        background-color: #000;
        padding: 87px 10px 71px;
        transition:background-color 0.3s ease;
        display: block;
        position: relative;
        text-decoration: none;
        text-align: center;
        color:#fff;

        @each $name, $color in $_color {
            #{$_}.-#{$name} &{
                background-color: nth($color, 1);
                text-shadow: nth($color, 3);
                &.-active {
                    background-color: nth($color, 2)
                }
            }
            @include tabletPmin {
                #{$_}.-#{$name} &{
                    &:hover{
                        background-color: nth($color, 2)
                    }
                }
            }
        }

        @include tabletPmin{
            margin-bottom: 1%;
            &:hover,
            &.-active{
                opacity: 1;

                &::before,
                &::after,
                #{$_}__inner::before,
                #{$_}__inner::after{
                    opacity: 1;
                }
            }
        }

        @at-root #{$_}.-reason & {
            @include tabletPmin {
                padding: 99px 10px 104px;
            }
        }

        @include tabletP{
            margin-top: 2%;
            padding: #{get_vw(45,375)} 5px;

            @at-root #{$_}.-spType02 & {
                width: 100%;
                padding: #{get_vw(17,375)} 5px;
                &:not(:first-child){
                    margin-top: 2%;
                }
            }
        }

    }

    &.-small{
        #{$_}__item{
            @include tabletPmin{
                padding-top: 31px;
                padding-bottom: 31px;
            }
        }


        #{$_}__item::before,
        #{$_}__item::after,
        #{$_}__inner::before,
        #{$_}__inner::after{
            display: none;
        }


        #{$_}__main{
            @include tabletPmin{
                @include fz(22);
                line-height: 1;
            }
        }

        #{$_}__sub{
            @include tabletPmin{
                margin-bottom: 8px;
                @include fz(24);
                line-height: 1;
            }
        }

        #{$_}__en{
            @include tabletPmin{
                @include fz(18);
                line-height: 1.4;
            }
        }
    }


    &__item::before,
    &__item::after,
    &__inner::before,
    &__inner::after{
        content:"";
        position: absolute;
        background-color: #fff;
        opacity: 0;
        transition:opacity 0.3s ease;
    }

    &__item::before,
    &__inner::before{
        //tate
        width: 1px;
        height: 50px;
    }

    &__item::after,
    &__inner::after{
        //yoko
        width: 80%;
        height: 1px;
    }

    &__item::before{
        top: 12px;
        left: 32px;
    }

    &__item::after{
        top: 25px;
        left: 18px;
    }

    &__inner::before{
        bottom: 12px;
        right: 32px;
    }

    &__inner::after{
        bottom: 25px;
        right: 18px;
    }

    &__head{

        @include tabletP{
            min-height: 3em;

            @at-root #{$_}.-spType02 & {
                min-height: unset;
            }
        }

    }

    &__main,
    &__sub,
    &__en{
        display: block;
    }

    &__main{
        @include fz(25);
        font-family:Gill Sans, Inter, "Helvetica Neue", Arial, "游ゴシック体", YuGothic, sans-serif;
        @include max(1024){
            font-size: get_vw(20,1040);
        }

        @include tabletP{
            font-size: get_vw(16,375);
        }

    }

    &__sub{
        @include fz(18);
        font-family:  Gill Sans, Inter, "Helvetica Neue", Arial, sans-serif;
        letter-spacing: 0;
        @include max(1024){
            font-size: get_vw(18,1040);
        }

        @include tabletP{
            font-size: get_vw(14,375);
        }

        @include sphoneP{
            font-size: get_vw(13,375);

        }
    }

    &__en{
        margin-top: 4px;
        @include fz(25);
        font-family: Gill sans, Inter, "Helvetica Neue", Arial, sans-serif;
        font-weight: 600;
        @include max(1024){
            font-size: get_vw(19,1040);
        }
        @include tabletP{
            font-size: get_vw(14,375);

        }

        @at-root #{$_}.-spType02 & {
            margin-top: -2px;
            @include tabletP {
                margin-top: -6px;
            }
        }


    }


}//.modNav01
