@charset "utf-8";
#header {}
.gHeader {
    background-color: rgba($colorLandicNavy, 0);
    position: fixed;
    top: 0;
    width: 100%;
    transition: .2s;
    z-index: 5;

    &[data-scrolled = "true"] {
        background-color: rgba($colorLandicNavy, .2);
    }

    #page_asset & {
        background-color: rgba(#000, .5);
    }

    #page_top & {
        background-color: #F5F3F3;
    }

    @include print{
        display: none;
    }
    &__inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: $headerHeight;
        @include tabletP {
            height: $headerHeightSp;
        }
    }
    &__logo {
        height: 19px;
        margin-left: 32px;
        @include tabletP {
            width: 189px;
            height: 11px;
            margin-left: 12px;
        }
    }
    &-link {
        display: flex;
        margin: 0 50px+52px 0 auto;
        @include max(1280) {
            margin-right: get_vw(50+30, 1280);
        }
        @include tabletP {
            display: none;
        }
        &__item {
            @include fz(15);
            font-family: $baseFontFamilyBold;
            letter-spacing: 0.42em;
            line-height: 1;
            text-align: center;
            max-width: 180px;
            width: get_vw(200, 1280);
            @include max(1024) {
                @include fz_vw(11, 1024);
                width: get_vw(120, 1024);
            }
            &:not(:last-child) {
                margin-right: 21px;
                @include max(1024) {
                    margin-right: get_vw(10, 1024)
                }
            }
        }
        &__link {
            border-radius: 2px;
            display: block;
            position: relative;
            text-decoration: none;
            padding: 14px 10px 16px;
            &::before {
                content: "";
                position: absolute;
                border-top: 12px solid #fff;
                border-left: 12px solid transparent;
                top: 4px;
                right: 4px;
                width: 12px;
                height: 12px;
                transform-origin: right 0;
                transform: scaleX(2);
                @include max(1280) {
                    border-top-width: 8px;
                    border-left-width: 8px;
                    top: 3px;
                    right: 3px;
                    width: 8px;
                    height: 8px;
                }
            }
            &.-type01 {
                background-color: rgba(#293B5F, .8);
                color: #fff;
            }
            &.-type02 {
                background-color: rgba(#B3AC8F, .8);
                color: #000;
            }
            &.-type03 {
                background-color: #83522C;
                color: #fff;
            }
        }
    }
}//.gHeader

.gNav {
    $_: &;
    background-color: rgba(#000, .8);
    display: none;
    position: fixed;
    z-index: 3;
    @include tabletPmin {
        top: 40px;
        left: 40px;
        max-width: 600px;
        width: calc(100vw - 40px * 2);
        max-height: calc(100vh - 40px * 2);
        padding: 40px 0 36px 74px;
    }
    @include tabletP {
        background-color: rgba(#000, .9);
        top: $headerHeightSp;
        left: 0;
        width: 100%;
        height: calc(100% - #{$headerHeightSp});
        overflow: hidden;
    }
    &__close {
        background: none;
        border: none;
        position: absolute;
        top: 14px;
        right: 19px;
        display: block;
        font-family: $fontA1Gothic;
        font-weight: 500;
        width: 28px;
        height: 28px;
        z-index: 2;
        @include tabletP {
            display: none;
            border-radius: 10px;
            top: 16px;
            right: 18px;
            width: 40px;
            height: 40px;
        }
        &::before,
        &::after {
            content: "";
            background-color: #fff;
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 88%;
            height: 1px;
            margin: auto;
        }
        &::before {
            transform:rotate(45deg);
        }
        &::after {
            transform:rotate(-45deg);
        }
    }
    &__inner {
        position: relative;
        width: 100%;
        height: 100%;
        @include tabletP {
            height: 100%;
            padding: 10px 0 40px;
            overflow: auto;
        }
    }
    &__list {
        @include tabletP {
            margin-bottom: 12px;
        }
    }
    &__group,
    &__item {
        @include tabletPmin {
            &:not(:first-child) {
                margin-top: 7px;
            }
        }
    }
    &__item {
        color: #fff;
        line-height: 1.4;
        @include tabletP {
            border-bottom: 1px solid #000;
        }
        &.-disabled {
            #{$_}-title,
            #{$_}-child {
                opacity: 0.6;
            }
        }
    }
    &-title {
        background: none;
        border: none;
        border-bottom: 1px solid transparent;
        outline: none;
        display: block;
        position: relative;
        color: inherit;
        text-align: left;
        text-decoration: none;
        width: 100%;
        padding: 0 0 8px 11px;
        @include tabletPmin {
            #{$_}__item:not(.-disabled)[data-gnavitem = "active"] &,
            #{$_}__item:not(.-disabled):hover & {
                opacity: 1;
                border-bottom-color: #fff;
            }
        }
        @include tabletP {
            padding: 5px 34px 3px 25px;
            &:not(a):not(span) {
                &::after {
                    content: "";
                    background:
                        linear-gradient(to bottom, transparent 6px, rgba(#fff, .5) 6px, rgba(#fff, .5) 7px, transparent 7px)
                    ,   linear-gradient(to right, transparent 6px, rgba(#fff, .5) 6px, rgba(#fff, .5) 7px, transparent 7px)
                    ;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 17px;
                    width: 13px;
                    height: 13px;
                    margin: auto;
                }
                #{$_}__item[data-gnavitem = "active"] & {
                    &::after {
                        background:
                            linear-gradient(to bottom, transparent 6px, rgba(#fff, .5) 6px, rgba(#fff, .5) 7px, transparent 7px)
                        ;
                    }
                }
            }
        }
        &__txt01,
        &__txt02 {
            display: block;
        }
        &__txt01 {
            color: #C7B299;
            @include fz(14);
            font-family: $fontMincho;
            font-weight: 500;
            letter-spacing: -.1em;
            line-height: 1.4;
            margin-bottom: 5px;
            @include tabletP {
                @include fz(10);
                margin-bottom: -3px;
                transform-origin: 0 0;
                transform: scale(.804);
            }
        }
        &__txt02 {
            color: #fff;
            @include fz(16);
            @include tabletP {
                @include fz(14);
            }
            &.-type02 {
                @include tabletP {
                    letter-spacing: -0.15em;
                }
            }
        }
    }
    &-child {
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        transition: .3s;
        overflow: hidden;
        @include tabletP {
            transition: .5s;
        }
        #{$_}__item[data-gnavitem = "active"] & {
            opacity: 1;
            visibility: visible;
            pointer-events: auto;
        }
        @include tabletPmin {
            position: absolute;
            left: 100%;
            max-width: 600px;
            width: calc(100vw - 40px * 2);
            margin-top: -61px;
            padding-top: 30px;
            #{$_}__item:hover & {
                opacity: 1;
                visibility: visible;
                pointer-events: auto;
            }
        }
        @include tabletP {
            #{$_}__item:not([data-gnavitem = "active"]) & {
                height: 0;
                pointer-events: auto;
            }
        }
        &__inner {
            background-color: rgba(#736357, .9);
            padding: 22px 20px 26px 47px;
            @include tabletP {
                padding: 14px 20px 12px 34px;
            }
        }
        &__item {
            display: flex;
            align-items: baseline;
            @include fz(14);
            line-height: 1.4;
            @include tabletP {
                @include fz(13);
            }
            &:not(:last-child) {
                margin-bottom: 13px;
                @include tabletP {
                    margin-bottom: 5px;
                }
            }
        }
        &__link {
            display: flex;
            color: inherit;
            text-decoration: none;
            &::before {
                content: "\25B6\FE0E";
                margin-right: 1em;
                flex-shrink: 0;
            }
        }
    }
    &-btn {
        @include tabletPmin {
            display: none;
        }
        &__list {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            max-width: 902+$containerPadding*2px;
            margin: auto;
            padding: 0 #{$containerPadding}px;
        }
        &__btn {
            width: 47.5%;
            @include sphoneP {
                width: 100%;
            }
            &:not(:first-child) {
                @include sphoneP {
                    margin-top: 16px;
                }
            }
        }
    }
}//.gNav

.gNavToggle {
    $_: ".gNavToggle";
    background: none;
    border: none;
    outline: none;
    display: block;
    position: relative;
    right: 40px;
    width: 50px;
    height: 40px;
    margin: 0;
    @include tabletP {
        right: 15px;
        width: 28px;
        height: 24px;
        margin: 0 0 0 auto;
    }
    &__bar {
        background-color: #fff;
        display: block;
        position: absolute;
        width: 100%;
        height: 4px;
        margin: auto;
        @include tabletP {
            height: 2px;
        }
        &:nth-child(1) {
            top: 0;
        }
        &:nth-child(2) {
            top: 0;
            bottom: 0;
        }
        &:nth-child(3) {
            bottom: 0;
        }
        @at-root #page_top & {
            background-color: #534741;
        }
    }
    @include tabletP {
        &[aria-expanded = "true"] &__bar {
            &:nth-child(1) {
                bottom: 0;
                transform: rotate(45deg);
            }
            &:nth-child(2) {
                transform: rotate(-45deg);
            }
            &:nth-child(3) {
                display: none;
            }
        }
    }
}//.gNavToggle

.gNavOverlay {
    $_: ".gNavOverlay";
    background-color: transparent;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    cursor: pointer;
    &[aria-hidden="false"] {
        height: 100%;
    }
}//.gNavOverlay
