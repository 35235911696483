@charset "utf-8";
#footer {}
.gFooter {
    $_: &;
    background-color: #000000;
    position: relative;
    color: #fff;
    padding: 89px 0 44px;
    z-index: 2;
    @include tabletP {
        padding: 42px 0 30px+65px;  // ボタンの高さ分
    }
    @include print{
        display: none;
    }
    &__logo {
        text-align: center;
        margin-bottom: 63px;
        @include tabletP {
            width: 133px;
            margin: 0 auto 30px;
        }
    }
    &__copyright {
        @include fz(14);
        font-family: $fontMontserrat;
        font-weight: 600;
        letter-spacing: .04em;
        text-align: center;
        margin-top: 21px;
        @include tabletP {
            margin-top: 18px;
            @include fz(10);
        }
    }
}//.gFooter

.gFNav {
    $_: &;
    @include tabletP {
        max-width: 345px;
        margin: 0 auto;
    }
    &__group {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        &:not(:last-child) {
            margin-bottom: 3px;
        }
    }
    &__item {
        @include fz(15);
        letter-spacing: -.025em;
        @include tabletP {
            @include fz(11);
        }
        &:not(:first-child) {
            &::before {
                content: "\3000\FF5C\3000";
            }
        }
        &.-lineNoneSp {
            @include tabletP {
                &::before {
                    content: none;
                }
            }
        }
    }
    &__link {
        color: inherit;
        text-decoration: none;
        &:hover {
            opacity: 1;
            text-decoration: underline;
        }
    }
}//.gFNav

.gPageTop {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
    transition: .2s;
    z-index: 3;
    @include tabletP {
        bottom: 86px;
    }
    &[aria-hidden = "true"] {
        opacity: 0;
        pointer-events: none;
    }
    &::before,
    &::after {
        content: "";
        background-color: #3D3D88;
        display: block;
        position: absolute;
        top: 0;
        right: 5px;
        width: 1px;
        height: 100%;
    }
    &::after {
        transform-origin: center 0;
        transform: rotate(45deg);
    }
}//.gPageTop

.gFootBtn {
    display: none;
    justify-content: center;
    position: fixed;
    bottom: 23px;
    width: 100%;
    margin: auto;
    z-index: 2;
    @include tabletP {
        display: flex;
    }
    &__btn {
        width: 106px;
        margin: 0 4.5px;
        & > * {
            height: 100%;
        }
    }
}//.gFootBtn
