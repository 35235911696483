@charset "utf-8";

/*
counseling_detail
*/
$id:counseling_detail;
$cmnPath:'../img/common/';
$imgPath:'../img/#{$id}/';
body#page_#{$id} {
    $_root: &;
    .gHeader {
        background-color: #7E7E7E;
    }

    .myWrapper {
        $_: ".myWrapper";
        padding-bottom: 60px;
        @include tabletP {
            padding-bottom: 30px;
        }
    }//.myWrapper

    .myBlockPresent {
        $_: ".myBlockPresent";
        background-color: #566674;
        margin-bottom: 100px;
        padding: 20px 0 20px;
        @include tabletP {
            margin-bottom: 33px;
            padding-bottom: 30px;
        }
        &__container {
            @include tabletP {
                max-width: 490px;
                padding-left: 20px;
                padding-right: 20px;
            }
        }
        &__inner {
            position: relative;
            padding: 22px 0 21px 40px;
            @include tabletPmin {
                display: flex;
                flex-wrap: wrap;
            }
            @include tabletP {
                padding: 18px 30px 19px;
            }
            &::before,
            &::after {
                content: "";
                position: absolute;
                pointer-events: none;
                width: calc(100% - 40px);
                height: calc(100% - 40px);
                @include tabletP {
                    width: calc(100% - 30px);
                    height: calc(100% - 30px);
                }
            }
            &::before {
                top: 0;
                left: 0;
                border-top: 1px solid #E5C50C;
                border-left: 1px solid #E5C50C;
            }
            &::after {
                bottom: 0;
                right: 0;
                border-bottom: 1px solid #E5C50C;
                border-right: 1px solid #E5C50C;
            }
        }
        &__title {
            color: #e5c50c;
            @include fz(24);
            font-family: $baseFontFamilyBold;
            font-weight: bold;
            letter-spacing: 0.04em;
            line-height: 1.4;
            width: 100%;
            margin-bottom: 8px;
            @include tabletP {
                text-align: center;
                margin-bottom: 4px;
            }
        }
        &__txt {
            color: #fff;
            @include fz(20);
            font-family: $baseFontFamilyBold;
            font-weight: bold;
            letter-spacing: 0.04em;
            line-height: (30 / 20);
            text-indent: -1em;
            padding-left: 1em;
            @include tabletPmin {
                min-width: 22.5em;
            }
            @include tabletP {
                @include fz(16);
                line-height: (24 / 16);
            }
        }
        &__caution {
            color: #fff;
            letter-spacing: 0.04em;
            line-height: (30 / 16);
            text-indent: -1em;
            padding-left: 1em;
            margin: 15px 0 0 17px;
            @include tabletP {
                @include fz(12);
                line-height: 2;
                margin: -2px 0 0 13px;
            }
        }
        &__image {
            @include tabletPmin {
                position: absolute;
                bottom: -34px;
                right: 23px;
                width: 301px;
                z-index: 2;
                @include max(1024) {
                    bottom: 2px;
                    right: -20px;
                    width: 210px;
                }
            }
            @include tabletP {
                text-align: center;
                margin: -2px 0px 0 12px;
            }
        }
        &__link {
            flex-shrink: 0;
            align-self: flex-end;
            position: relative;
            max-width: 190px;
            width: 100%;
            z-index: 3;
            @include tabletPmin {
                top: 7px;
                left: -27px;
                margin-right: -30px;
            }
            @include tabletP {
                position: absolute;
                bottom: -21px;
                left: 0;
                right: 0;
                margin: 0 auto;
            }
            &-inner {
                background-color: #fff;
                border: 1px solid #000;
                display: block;
                position: relative;
                color: #000;
                @include fz(18);
                font-family: $baseFontFamilyBold;
                font-weight: bold;
                letter-spacing: 0.04em;
                line-height: 1.4;
                text-align: center;
                text-decoration: none;
                width: 100%;
                padding: 9px 30px 7px;
                &::after {
                    content: "";
                    border-top: 1px solid #000;
                    border-right: 1px solid #000;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 17px;
                    width: 10px;
                    height: 10px;
                    margin: auto;
                    transform: rotate(45deg);
                }
            }
        }
    }//.myBlockPresent

    .myBlockVoice {
        $_: ".myBlockVoice";
        margin-bottom: 100px;
        @include tabletP {
            margin-bottom: 40px;
        }
        &__container {
            max-width: 974px;
            @include tabletP {
                max-width: 450px;
                padding-left: 0;
                padding-right: 0;
            }
        }
        &__title {
            text-align: center;
            margin-bottom: 41px;
            @include tabletP {
                margin-bottom: -1px;
            }
        }
        &__item {
            position: relative;
            &::before {
                content: "";
                background-repeat: no-repeat;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
            }
            &:not(:last-child) {
                margin-bottom: 48px;
                @include tabletP {
                    margin-bottom: 28px;
                }
            }
            &.-item01 {
                @include tabletPmin{
                    margin-left: 8px;
                    padding: 45px 30px 0px 194px;
                    &::before {
                        background-image: url(#{$imgPath}bg_voice01.png);
                        background-position: left 0;
                        background-size: 890px;
                        bottom: 6px;
                        @include retina(){
                            background-image: url(#{$imgPath}bg_voice01@2x.png);
                        }
                    }
                }
                @include tabletP{
                    margin-right: 20px;
                    padding: 39px 0 0 135px;
                    &::before {
                        background-image: url(#{$imgPath}bg_voice01_sp.png);
                        background-position: left -13px top 0;
                        background-size: 766px;
                        bottom: -13px;
                        @include retina(){
                            background-image: url(#{$imgPath}bg_voice01_sp@2x.png);
                        }
                    }
                }
            }
            &.-item02 {
                @include tabletPmin{
                    margin-left: -2px;
                    margin-right: 20px;
                    padding: 30px 179px 0 31px;
                    &::before {
                        background-image: url(#{$imgPath}bg_voice02.png);
                        background-position: right 0;
                        background-size: 880px;
                        bottom: 0;
                        @include retina(){
                            background-image: url(#{$imgPath}bg_voice02@2x.png);
                        }
                    }
                }
                @include tabletP{
                    margin-left: 20px;
                    padding: 37px 130px 0 0;
                    &::before {
                        background-image: url(#{$imgPath}bg_voice02_sp.png);
                        background-position: right -31px top 0;
                        background-size: 766px;
                        bottom: -9px;
                        @include retina(){
                            background-image: url(#{$imgPath}bg_voice02_sp@2x.png);
                        }
                    }
                }
                #{$_}__item-title {
                    text-align: right;
                    @include tabletP {
                        margin-right: 10px;
                    }
                }
            }
            &-title {
                margin-bottom: 24px;
                @include tabletP {
                    margin-bottom: 2px;
                }
            }
            &-txt {
                color: #000;
                @include fz(20);
                letter-spacing: 0.04em;
                line-height: (30 / 20);
                @include tabletP {
                    @include fz(14);
                    line-height: (20 / 14);
                }
            }
        }
    }//.myBlockVoice
}